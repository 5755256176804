import {useState, useEffect} from 'react';

const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

/**
 * https://github.com/trekhleb/use-position
 * @param watch
 * @param settings
 * @returns {{error: unknown}}
 */

export const usePosition = (watch = false, settings = defaultSettings) => {
    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);

    const onChange = ({coords, timestamp}) => {
        // if(typeof console === 'object') { console.log('coords',coords,timestamp); }

        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            timestamp,
        });
    };

    const onError = (error) => {
        // if(typeof console === 'object') { console.log('onError',error); }
        setError(error.message);
    };

    useEffect(() => {
        const geo = navigator.geolocation;

        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }

        let watcher = null;
        if (watch) {
            watcher = geo.watchPosition(onChange, onError, settings);
        } else {
            geo.getCurrentPosition(onChange, onError, settings);
        }

        return () => watcher && geo.clearWatch(watcher);
    }, [settings, watch]);

    return {...position, error};
};