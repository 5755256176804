import React, { useState, useEffect } from 'react';
import PropTypes                           from 'prop-types';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {useIntl, defineMessages} from 'react-intl';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import MapsUtils from "./MapsUtils";
import CircularProgress          from '@material-ui/core/CircularProgress';
import AlertDialog from "../../common/AlertDialog";
import config from '../../config';

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
let autocompleteComp;
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		// width: '100%',
		width: 'calc(100% -10px)',
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
}));

const messages = defineMessages({
	searchPlaceholder: {
		id: 'search.placeholder',
		// defaultMessage: 'Search',
		// description: 'search.placeholder',
	},
	geoNotSupported: {
		id: 'geolocation.notsupported',
		// defaultMessage: 'Geolocation is not supported',
	},
	geoNotSupportedTitle: {
		id: 'geolocation.notsupportedtitle',
		// defaultMessage: 'Geolocation is not supported',
	}
});

function GoogleSearchBox(props) {
	const {
		setLocation,
		setZoom,
		setRef,
		setMarkerAddress,
		setMapInstanceCenter,
		removeDrawings,
		setMarkerPosition
	} = props;
	const classes = useStyles();
	const intl = useIntl();
	const [spin, setSpinner] = useState(false);
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
	    setOpen(false);
	};

	const onAutocompleteLoad = ref => {
		// if(typeof console === 'object') { console.log('onAutocompleteLoad',ref); }
		autocompleteComp = ref;
	};

	const getAutocompleteRef = ref => {
		// if(typeof console === 'object') { console.log('onAutocompleteLoad',ref); }
		return autocompleteComp;
	};

	const onPlacesChanged = () => {
		// if(typeof console === 'object') { console.log('autocompleteComp?',autocompleteComp); }
		let places = autocompleteComp.getPlaces();
		// console.log('onPlacesChanged',autocompleteComp,places,autocompleteComp.getPlaces());

		if(places[0] && places[0].geometry && places[0].geometry.location) {
			setLocation(places[0].geometry.location);
			setMarkerPosition(places[0].geometry.location);
			setZoom(config.google.searchBoxZoom);
			setMarkerAddress(places[0].formatted_address);
			removeDrawings();
		}
	};

	const getAutoComppleteInput = () => {
		return document.getElementById('google-autocomplete-input');
	};

	const clearinput = () => {
		let aci = getAutoComppleteInput();
		// console.log(autocompleteComp, aci);
		aci.value = '';
		aci.focus();
	};

	const getInputValue = () => {
		let i = getAutoComppleteInput();
		if(i && i.value) {
			return i.value;
		}
		return null;
	};

	const setInputValue = (value) => {
		let i = getAutoComppleteInput();
		if(i) {
			i.value = value;
		}
	};

	const geoLocate = () => {
		setSpinner(true);
		MapsUtils.geoLocate()
			.then((location) => {
				console.log('location',location);
				if(location) {
					setLocation(location.center);
					setMarkerPosition(location.center);
					setMapInstanceCenter(location.center);
				} else {
					setOpen(true);
				}
				setSpinner(false);
			})
			.catch((err) => {
				console.error('error',err.message);
				setSpinner(false);
			});
	};

	const submit = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		if(setRef && typeof setRef === 'function') {
			setRef({
				getAutoComppleteInput: getAutoComppleteInput,
				getAutocompleteRef: getAutocompleteRef,
				setInputValue: setInputValue,
				getInputValue: getInputValue,
				clearinput: clearinput,
			})
		}

	});

	return (
		<React.Fragment>
			<StandaloneSearchBox
				onLoad={onAutocompleteLoad}
				onPlacesChanged={
					onPlacesChanged
				}
			>
				<Paper component="form" className={classes.root} onSubmit={submit}>
					<InputBase
						id="google-autocomplete-input"
						className={classes.input}
						placeholder={intl.formatMessage(messages.searchPlaceholder)}
						inputProps={{ 'aria-label': 'search google maps' }}
					/>
					<IconButton
						type="button"
						className={classes.iconButton}
						aria-label="clear"
						onClick={clearinput}
					>
						<ClearIcon />
					</IconButton>
					<Divider className={classes.divider} orientation="vertical" />
					<IconButton
						color="primary"
						className={classes.iconButton}
						aria-label="directions"
						onClick={geoLocate}
					>
						{(!spin) ? <LocationSearchingIcon /> : <CircularProgress color="inherit" size={20} />}
					</IconButton>
				</Paper>
			</StandaloneSearchBox>
			{open && <AlertDialog
				open={open}
				handleClose={handleClose}
				title={intl.formatMessage(messages.geoNotSupportedTitle)}
				text={intl.formatMessage(messages.geoNotSupported)}
			/>}
		</React.Fragment>
	);
};

GoogleSearchBox.defaultProps = {

};

GoogleSearchBox.propTypes = {
	// classes: PropTypes.object,
	setLocation: PropTypes.func,
	setZoom: PropTypes.func,
};

export default GoogleSearchBox;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
