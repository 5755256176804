// const apiHost = process.env.REACT_APP_API_ENTRYPOINT;

let apiHost, GoogleApiKey, strapiHost;
if(window.location.host === 'localhost:3015') {
	apiHost = 'http://localhost:3016/airtable';
	GoogleApiKey = 'AIzaSyAiFeWaS3zkK3DIpy2tGxaB_eXDPFX4q9o';
	// strapiHost = "http://localhost:1337";
	strapiHost = "https://api.locator.capitalpv.com";
} else if(window.location.host.indexOf('yellowspace.net') > 0) {
	apiHost = 'https://capitalpv-locator.yellowspace.net/airtable';
	GoogleApiKey = 'AIzaSyB9rxkn9dOMYRwZo6yIB_c-RF_oW9Mz69Y';
	strapiHost = "https://api.locator.capitalpv.com";
}
else {
	apiHost = 'https://locator.capitalpv.com/airtable';
	GoogleApiKey = 'AIzaSyB9rxkn9dOMYRwZo6yIB_c-RF_oW9Mz69Y';
	strapiHost = "https://api.locator.capitalpv.com";
}
// if(typeof console === 'object') { console.log('apiHost',apiHost); }
// if(typeof console === 'object') { console.log('process.env.',process.env,apiHost); }

module.exports = {
	title: '',
	url: '',
	name: '',
	subtitle: '',
	owner: 'BytesAtWork Ltd.',
	favicon: '/public/_img/Yellowspace-Logo-1000x1000.png',
	output: {
        path: './build',
        publicPath: ''
    },
	i18n: {
		loadPath: '/src/i18n/'
	},
	api: {
		host: apiHost,
		path: apiHost+'/api.php',
		apiId: 'DkuUTsGhbsGgst651aaLPii872'
	},
	strapiApi: {
		host: strapiHost
	},
	google: {
		apiKey: GoogleApiKey,
		mapType: 'hybrid',
		libraries: ['places','drawing', 'geometry'],
		center: {
			lat: -3.745,
			lng: -38.523
		},
		zoom: 8,
		geoLocationZoom: 15,
		searchBoxZoom: 17,
		drawingZoom: 20,
	},
	airtable: {
		// tableId: 'appHMCrwLMeDNAqmw', // Flow Smart Solutions
		// tableId: 'appEW5tCnvpOdm1l2', // test @ airtable@cr.yellowspace.net
		// api_key: 'keyLc3Cf6asolFvNV', // capitalPV
		// api_key: 'keyA9XQsidAOLnEXh' // airtable@cr.yellowspace.net
	},
	host: {

	}
};
