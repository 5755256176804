import React, { useState, useEffect } from 'react';
// import PropTypes                           from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../config';
import Button from '@material-ui/core/Button';
import Room from "@material-ui/icons/Room";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import MapsUtils from "../scenes/location/MapsUtils";
import CircularProgress          from '@material-ui/core/CircularProgress';

// import {defineMessages} from "react-intl";
import {defineMessages, useIntl} from 'react-intl';
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";


const useStyles = makeStyles(theme => ({
	root: {
		margin: '20px 0 0 0',
		width: '100%',
	},
	ExpansionPanelDetailsRoot: {
		display: 'block'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	buttons: {
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
	},
	buttonsContainer: {
		textAlign: 'right',
	}
}));

const messages = defineMessages({
	sendData: {
		id: 'map.sendData'
	},
	resetData: {
		id: 'map.resetData'
	},
});

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

function DumpForm(props) {
	const classes = useStyles();
	const intl = useIntl();

	const {
		zoom,
		center,
		markerAddress,
		markerPosition,
		markerInstance,
		propertyBorderCoordinates,
		propertyRectangleCoordinates,
		propertyRoofCoordinates,
		propertyRectangleRotation,
		// propertyRoofCoordinates,
		propertyType,
		propertyBorderEdges,

		propertyRectangle,
		propertyBorders,
		propertyRoof,
		updateAirTableRecord,
		reloadAppData,
		resetMapaData,
		useMarker,
		record,
		recordMode,
		address,
		sendSpin,
		mapHasProjection,

		USGSOverlay,
		mapInstance,
		// propertyRoofEdges,
	} = props;

	const [staticMapUrl, setStaticMapUrl] = useState(null);
	const [bestCenter, setBestCenter] = useState(null);

	// if(typeof console === 'object') { console.log('propertyBorderspropertyBorderspropertyBorders',propertyBorders); }

	useEffect(
		() => {
			// if(typeof console === 'object') { console.log('DumpForm.window.messageEvent',window.messageEvent,window.eventer); }
			// window.eventer(window.messageEvent, function(e) {
			// 		if(typeof console === 'object') { console.log('DumpForm.eventer',e); }
			// 		if (isNaN(e.data)) return;
			// 		window.sendHeight();
			// 	},
			// 	false);
			if(typeof window.sendHeight === 'function') {
				window.sendHeight();
				document.body.onresize = function () {
					// if(typeof console === 'object') { console.log('RESIZE',true); }
					setTimeout(() => {
						window.heightSend = false;
						window.sendHeight();
					}, 500);
				};
			}
		},
		[],
	);

	useEffect(
		() => {
			if(mapHasProjection) {
				getBestCenter();
			}
		},
		[mapHasProjection],
	);

	const getPropertyBordersCenter = (pB) => {
		pB = pB || propertyBorders;
		let center = null;
		let pCenter = MapsUtils.getPolygonCenter(pB);
		if(pCenter && typeof pCenter.lat === 'function') {
			center = {
				lat: pCenter.lat(),
				lng:  pCenter.lng()
			};
		}
		return center;
	};

	const getBestCenter = () => {
		// pB = pB || propertyBorders;
		let lat, lng;
		if(typeof center.lat === 'function') {
			lat = center.lat();
			lng = center.lng();
		} else {
			lat = center.lat;
			lng = center.lng;
		}

		let pCenter = getPropertyBordersCenter(propertyBorders); //MapsUtils.getPolygonCenter(propertyBorders);
		if(pCenter) {
			setBestCenter(pCenter);
			lat = pCenter.lat;
			lng = pCenter.lng;
		}

		return {
			lat: lat,
			lng: lng
		}
	};


	const saveAll = () => {
		// let _r_Center = center;
		// let pCenter = getPropertyBordersCenter(); //MapsUtils.getPolygonCenter(propertyBorders);
		// if(pCenter) {
		// 	_r_Center = pCenter;
		// }

		let _r_Center = getBestCenter();

		let s = {
			zoom: zoom,
			center: _r_Center,
			mapCenter: center,
			address: address,
			// markerAddress:markerAddress,
			// markerPosition: (markerInstance) ? markerInstance.position : center,
			propertyBorderCoordinates: propertyBorderCoordinates,
			propertyRectangleCoordinates: propertyRectangleCoordinates,
			propertyRoofCoordinates: propertyRoofCoordinates,
			propertyRectangleRotation: propertyRectangleRotation,
			// propertyRoofCoordinates: propertyRoofCoordinates,
			propertyBorderEdges: propertyBorderEdges,
			// propertyRoofEdges: propertyRoofEdges,
			propertyType: propertyType,
		};

		if(useMarker) {
			s.markerAddress = markerAddress;
			s.markerPosition = (markerInstance) ? markerInstance.position : center;
		}

		window.localStorage.setItem('mapData', JSON.stringify(s, 0, 2));
	};


	useEffect(() => {
		saveAll();

	},[
		zoom,
		center,
		address,
		markerAddress,
		markerPosition,
		propertyBorderCoordinates,
		propertyRectangleCoordinates,
		propertyRoofCoordinates,
		propertyRectangleRotation,
		// propertyRoofCoordinates,
		propertyType,
		propertyBorderEdges,
		// propertyRoofEdges,
	]);

	/**
	 * https://stackoverflow.com/questions/31277220/gmap-drawing-tools-to-image-jpeg-static-map-url
	 */
	const getStaticImage = (
		minusZoom = 0
	) => {

		let rect = getMapCoordinates();
		let w = rect.width;
		let h = rect.height;
		// let size = '512x512';

		w = parseInt(w);
		if(w < 400) {
			w = 400;
		}

		let size = w+'x600';

		let lat, lng;
		let centerCoordinates = getBestCenter();
		lat = centerCoordinates.lat;
		lng = centerCoordinates.lng;

		// if(typeof center.lat === 'function') {
		// 	lat = center.lat();
		// 	lng = center.lng();
		// } else {
		// 	lat = center.lat;
		// 	lng = center.lng;
		// }

		//https://stackoverflow.com/questions/31277220/gmap-drawing-tools-to-image-jpeg-static-map-url
		//https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12&size=400x400&maptype=roadmap&key=YOUR_API_KEY
		let staticMap = 'https://maps.googleapis.com/maps/api/staticmap?size='+size+'&maptype=' + config.google.mapType;
		staticMap += '&key=' + config.google.apiKey;
		staticMap += '&zoom=' + (zoom + minusZoom);
		// staticMap += '&zoom=' + zoom;

		if(useMarker && markerInstance) {
			staticMap += "&markers=color:red|" + markerInstance.getPosition().toUrlValue(6);
		}

		if(propertyBorderCoordinates) {
			// let pCenter = getPropertyBordersCenter(); //MapsUtils.getPolygonCenter(propertyBorders);
			// if(pCenter) {
			// 	lat = pCenter.lat;
			// 	lng = pCenter.lng;
			// }


			let p = MapsUtils.staticMap_getPolygon(propertyBorders);
			staticMap += '&'+p;
		}

		if(propertyRoofCoordinates) {
			let p = MapsUtils.staticMap_getPolygon(propertyRoof);
			staticMap += '&'+p;
		}

		if(propertyRectangleCoordinates) {
			let p = MapsUtils.staticMap_getPolygon(propertyRectangle,{fillColor:'#000000'});
			staticMap += '&'+p;
		}

		staticMap += '&center=' + lat+','+lng;

		// style
		// staticMap += '&style=feature:poi.attraction%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:poi.business%7Celement:geometry.fill%7Cvisibility:off&style=feature:poi.government%7Cvisibility:off&style=feature:poi.medical%7Cvisibility:off&style=feature:poi.place_of_worship%7Cvisibility:off&style=feature:poi.school%7Cvisibility:off';

		setStaticMapUrl(staticMap);

		return staticMap;
	};



	const getMapCoordinates = () => {

		let mapContainer = document.getElementById('mapContainer');

		if (mapContainer) {
			return mapContainer.getBoundingClientRect();
		}

	};


	const updateRecord = () => {
		let mapImage = getStaticImage();
		let mapImageLarge = getStaticImage(-1);
		// let data = window.localStorage.getItem('mapData');

		let centerCoordinates = getBestCenter();
		let backliink = 'https://capitalpv-locator.yellowspace.net/?recordId='+record.id+'&apiId='+config.api.apiId+'&airtableTableId='+window.locationTool.airtableTableId+'&airtableTableBase='+window.locationTool.airtableTableBase;
		if(window.locationTool.airtableAddressField) {
			backliink += '&airtableAddressField='+window.locationTool.airtableAddressField;
		}

		let data = {
			// lt_test: 'Test1',
			'Latitude (projet) Value': centerCoordinates.lat,
			'Longitude (projet) Value': centerCoordinates.lng,
			lt_address: address,
			lt_backLink: backliink,
			lt_staticmapimageurl: mapImage,
			lt_mapData: window.localStorage.getItem('mapData'),
			lt_mapimage: [{
				// "id": "att6f75cc83f1b648",
				// "size": 26317,
				"url": mapImage,
				"filename": "map",
				// "type": "image/png",
				// "filename": "test.png",
				// "thumbnails": {
				// 	// "small": {
				// 	// 	"url": "https://www.filepicker.io/api/file/Dy5gioxaShSUvHX0LgIC",
				// 	// 	"width": 54,
				// 	// 	"height": 36
				// 	// },
				// 	"large": {
				// 		"url": mapImage,
				// 		"width": 512,
				// 		"height": 512
				// 	}
				// }
			},
				{
					"url": mapImageLarge,
					"filename": "mapZoomed",
				}]
		};

		// if(typeof console === 'object') { console.log('data',data); }
		// return;
		updateAirTableRecord(null,data);
	};

	const resetRecord = () => {
		// window.localStorage.setItem('mapData', {});
		window.localStorage.removeItem('mapData');
		resetMapaData();
		setTimeout(() => {
			reloadAppData();
		}, 1000);

	};

	const testButton = false;
	const testSomething = () => {
		if(typeof window.sendScrollTop === 'function') {
			window.sendScrollTop();
		}
	};



	return (
		<React.Fragment>
			<div className={classes.buttonsContainer}>
				<Button
					className={classes.buttons}
					startIcon={<Cancel />}
					variant="outlined"
					color="primary"
					onClick={resetRecord}
				>
					{intl.formatMessage(messages.resetData)}
				</Button>
				<Button
					className={classes.buttons}
					startIcon={(!sendSpin) ? <Save /> : <CircularProgress color="inherit" size={20} />}
					variant="contained"
					color="primary"
					onClick={updateRecord}
					disabled={!record}
				>
					{intl.formatMessage(messages.sendData)}
				</Button>
				{testButton &&<Button
					className={classes.buttons}
					startIcon={(!sendSpin) ? <Save /> : <CircularProgress color="inherit" size={20} />}
					variant="contained"
					color="primary"
					onClick={testSomething}
				>
					testSomething
				</Button>}
			</div>
			{recordMode &&<ExpansionPanel className={classes.root}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Form and Map Informations (only available in "record mode")</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.ExpansionPanelDetailsRoot}>
					<Typography className={classes.heading}>propertyType</Typography>
					<pre>{JSON.stringify(propertyType, 0, 2)}</pre>
					<Typography className={classes.heading}>Zoom</Typography>
					<pre>{JSON.stringify(zoom, 0, 2)}</pre>
					<Typography className={classes.heading}>Address</Typography>
					<pre>{JSON.stringify(address, 0, 2)}</pre>
					<Typography className={classes.heading}>Center</Typography>
					<pre>{JSON.stringify(center, 0, 2)}</pre>
					<Typography className={classes.heading}>Border Center (or Address Center)</Typography>
					<pre>{JSON.stringify(bestCenter, 0, 2)}</pre>

					{useMarker && markerAddress &&<React.Fragment>
						<Typography className={classes.heading}>markerAddress</Typography>
						<pre>{JSON.stringify(markerAddress, 0, 2)}</pre>
					</React.Fragment>}

					{useMarker && markerInstance &&<React.Fragment>
						<Typography className={classes.heading}>Marker</Typography>
						<pre>{JSON.stringify(markerInstance.position, 0, 2)}</pre>
					</React.Fragment>}

					{propertyBorderCoordinates &&<React.Fragment>
						<Typography className={classes.heading}>Property Border coordinates</Typography>
						<pre>{JSON.stringify(propertyBorderCoordinates, 0, 2)}</pre>
					</React.Fragment>}

					{propertyRoofCoordinates &&<React.Fragment>
						<Typography className={classes.heading}>Property Roof coordinates</Typography>
						<pre>{JSON.stringify(propertyRoofCoordinates, 0, 2)}</pre>
					</React.Fragment>}


					{propertyRectangleRotation > 0 && <React.Fragment><Typography className={classes.heading}>propertyRectangleRotation</Typography>
						<pre>{JSON.stringify(propertyRectangleRotation, 0, 2)}</pre></React.Fragment>}

					{propertyRectangleCoordinates &&<React.Fragment>
						<Typography className={classes.heading}>Property Hangar coordinates</Typography>
						<pre>{JSON.stringify(propertyRectangleCoordinates, 0, 2)}</pre>
					</React.Fragment>}

					{staticMapUrl && <img alt={staticMapUrl} src={staticMapUrl} />}
					{staticMapUrl && <a style={{display: 'block', margin: '10px 0 10px 0'}} href={staticMapUrl} target="_blank" title={staticMapUrl}>Get Image</a>}

				</ExpansionPanelDetails>
				<div>
					<Button
						className={classes.buttons}
						startIcon={<Room />}
						variant="contained"
						color="primary"
						onClick={getStaticImage}
					>
						get static map Image
					</Button>

				</div>
			</ExpansionPanel>}
		</React.Fragment>
	);
};

DumpForm.defaultProps = {

};

DumpForm.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};



export default DumpForm;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
