class AirtableUtils {

    /**
     *
     jedes Flow hat seine eigenen Eigenschaften, aber auch wenn eventuell anders genannt,
     haben wir max diese drei Möglichkeiten (hangar, roof, land.)

     Flow Francais - type de projet
     Location = roof
     Rénovation = roof
     Hangar = hangar
     Hangar + PV clé en main = hangar
     Centrale clé en main = roof

     Flow Allemand = IMMER “roof"

     Flow Centrales au sol = IMMER “land"

     Flow Landverpachten = IMMER “land”

     Flow Affitto terreno = IMMER “land”

     Flow Collectivité locale - type de projet
     Location de toiture = roof
     Ombrière de parking photovoltaïque = hangar
     Hangar sportif ou couverture d’un terrain = hangar
     *
     * @param savedMapData
     * @param record
     * @param AirtableId
     * @returns {string}
     */
    static guessPropertyType(savedMapData,record,airtableSettings) {

        let propertyType = 'roof';

        if(savedMapData && typeof savedMapData === 'object' && savedMapData.propertyType) {
            propertyType = savedMapData.propertyType;
        }

        // if(typeof console === 'object') { console.log('airtableSettings',airtableSettings); }
        if(airtableSettings.propertyType && airtableSettings.propertyType.length > 0) {
            propertyType = airtableSettings.propertyType;
        }

        if(record && typeof record === 'object') {
            if(airtableSettings.propertyTypeRecordField && airtableSettings.propertyTypeRecordField.length > 0) {
                if(record['fields'] && record['fields'][airtableSettings.propertyTypeRecordField]) {
                    let guessPropertyTypeField = record['fields'][airtableSettings.propertyTypeRecordField];
                    if(typeof airtableSettings.propertyTypeMatch === 'object' && airtableSettings.propertyTypeMatch.length > 0) {
                        airtableSettings.propertyTypeMatch.forEach((match) => {
                            if(guessPropertyTypeField === match.propertyTypeMatch) {
                                propertyType = match.propertyTypes;
                            }
                        });
                    }
                }
            }



            // switch (airtableSettings.AirtableId) {
            //     case 'appWM3Qs0JWHhJ9V8': // flow Francais
            //         if(record['fields'] && record['fields']['Type de projet']) {
            //
            //             switch (record['fields']['Type de projet']) {
            //                 case'Hangar':
            //                 case'Hangar + PV clé en main':
            //                     propertyType = 'hangar';
            //                     break;
            //                 case'Location':
            //                 case'Rénovation':
            //                 case'Centrale clé en main':
            //                 default:
            //                     propertyType = 'roof';
            //                     break;
            //             }
            //         }
            //         break;
            //     case'appUuCyAJ3s9sf7nQ': // flow allemand
            //         propertyType = 'roof';
            //         break;
            //     case'appGsTcVPhQgaxW4D': // Flow Centrales au sol
            //     case'app2LUdKjWFIUUtnB': // Landverpachten
            //     case'appgKSStMJbNitlRf': // Affitto terreno
            //         propertyType = 'land';
            //         break;
            //     case'appTUoxrmcG0UMmoW': // Collectivité locale
            //         if(record['fields'] && record['fields']['Type de projet']) {
            //             switch (record['fields']['Type de projet']) {
            //                 case'Location de toiture':
            //                     propertyType = 'roof';
            //                     break;
            //                 case'Hangar sportif ou couverture d’un terrain':
            //                 case'Ombrière de parking photovoltaïque':
            //                 default:
            //                     propertyType = 'hangar';
            //                     break;
            //             }
            //         }
            //         break;
            // }


        }

        return propertyType;
    };

    static guessRedirect(airtableSettings) {

        let redirect = null;

        if(airtableSettings.redirect && airtableSettings.redirect.length > 1) {
            redirect = airtableSettings.redirect;
        }

        // switch (AirtableId) {
        //     case 'appWM3Qs0JWHhJ9V8': // flow Francais
        //     case'appGsTcVPhQgaxW4D': // Flow Centrales au sol
        //         redirect = 'https://capitalpv.setmore.com';
        //         break;
        // }

        return redirect;
    };

    //AirtableId,airtableAddressField
    static guessAddress(record,airtableSettings) {

        let address = null;

        if(record && typeof record === 'object') {

            if(airtableSettings.airtableAddressField) {
                let aU = airtableSettings.airtableAddressField;//window.decodeURIComponent(airtableSettings.airtableAddressField);
                if(record['fields'] && record['fields'][aU]) {
                    address = record['fields'][aU];
                }
            } else {
                // Todo, delete this
                switch (airtableSettings.AirtableId) {
                    case 'appWM3Qs0JWHhJ9V8': // flow Francais
                        if(record['fields'] && record['fields']['Adresse complète']) {
                            address = record['fields']['Adresse complète'];
                        }
                        break;
                    case'app2LUdKjWFIUUtnB':
                        if(record['fields'] && record['fields']['Adresse']) {
                            address = record['fields']['Adresse'];
                        }
                        break;
                }
            }


        }

        return address;

    }

}

export default AirtableUtils;