import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import config from './config';
// import * as serviceWorker from './serviceWorker';
// import acceptLanguage from 'accept-language';
import { IntlProvider } from 'react-intl';
import LoadingIndicator from "./common/LoadingIndicator";
import {getData} from "./api/strapiApi";
// import Airtable from 'airtable';
// acceptLanguage.languages(['en', 'de']);

// function detectLocale(req) {
//     const cookieLocale = localStorage.getItem('locale');//req.cookies.locale;
//     if(typeof console === 'object') { console.log('',true); }
//     return acceptLanguage.get(cookieLocale || req.headers['accept-language']) || 'en';
// }

/**
 * Internationalization
 * https://github.com/formatjs/react-intl/blob/master/docs/Components.md#intlprovider
 * https://www.smashingmagazine.com/2017/01/internationalizing-react-apps/
 *
 * @returns {{length}|string|*|null}
 */

function getFirstBrowserLanguage () {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return null;
};

const localeProp = window.locationTool.language || getFirstBrowserLanguage().slice(0,2);
// if(typeof console === 'object') { console.log(' window.locationTool.language', window.locationTool.language); }
// if(typeof console === 'object') { console.log(' getFirstBrowserLanguage().slice(0,2)', getFirstBrowserLanguage().slice(0,2)); }
// console.log(getFirstBrowserLanguage().slice(0,2));
const defaultLocale = 'en';

// const messages = {
//     "en": {
//         "home.welcome": "Location Tool {name}",
//         "search.placeholder": "Search",
//         "geolocation.notsupported": "Geolocation is not supported",
//         "geolocation.notsupportedtitle": "Geolocation",
//         "map.stepOne": "Please insert your Address (or click on the right Button to locate your position).",
//         "map.stepThree": "Please define the borders of your property.",
//         "map.stepHangar": "Please place the hangar to your property.",
//         "map.stepPropertyRoof": "Please define the roof of your property.",
//         "map.drawRoof": "Draw Roof",
//         "map.propertyBorders": "Draw property borders",
//         "map.propertyRoof": "Draw roof",
//         "map.propertyHangar": "Place hangar",
//         "map.propertyRotate": "Rotate",
//         "map.sendData": "Send",
//         "map.resetData": "Reset",
//         "map.finalMessage": "Thank you. We will get back to you soon.",
//         "map.finalMessageTxt": " ",
//     },
//     "de": {
//         "home.welcome": "Location Tool {name}",
//         "search.placeholder": "Suche",
//         "geolocation.notsupported": "Geolokalisierung ist nicht verfügbar.",
//         "geolocation.notsupportedtitle": "Geolokalisierung",
//         "map.stepOne": "Bitte geben Sie Ihre Adresse ein oder klicken Sie auf den rechten Button, um Ihre Position zu bestimmen.",
//         "map.stepThree": "Bitte zeichnen Sie die Grenzen Ihrer Freifläche ein.",
//         "map.stepHangar": "Bitte platzieren Sie den Hangar auf Ihrem Eigentum.",
//         "map.stepPropertyRoof": "Bitte zeichnen Sie die Dachflächen ein.",
//         "map.drawRoof": "Dach zeichnen",
//         "map.propertyBorders": "Grenzen einzeichnen ",
//         "map.propertyRoof": "Dachflächen einzeichnen",
//         "map.propertyHangar": "Hangar platzieren",
//         "map.propertyRotate": "Drehen",
//         "map.sendData": "Abschicken",
//         "map.resetData": "Zurücksetzen",
//         "map.finalMessage": "Vielen Dank, wir werden uns umgehend bei Ihnen melden.",
//         "map.finalMessageTxt": " ",
//     },
//     "fr": {
//         "home.welcome": "Localisation projet {name}",
//         "search.placeholder": "Chercher",
//         "geolocation.notsupported": "La géolocalisation ne fonctionne pas",
//         "geolocation.notsupportedtitle": "Géolocalisation",
//         "map.stepOne": "Merci d’entrer votre adresse (ou de cliquer sur le bouton à droite pour vous localiser).",
//         "map.stepThree": "Merci de délimiter vos parcelles.",
//         "map.stepHangar": "Merci de positionner le hangar sur vos parcelles.",
//         "map.stepPropertyRoof": "Merci de sélectionner la toiture de vos bâtiments.",
//         "map.drawRoof": "Délimitez votre toit",
//         "map.propertyBorders": "Délimitez vos parcelles",
//         "map.propertyRoof": "Délimitez votre toit",
//         "map.propertyHangar": "Placez votre hangar",
//         "map.propertyRotate": "Rotation",
//         "map.sendData": "Envoyer",
//         "map.resetData": "Réinitialiser",
//         // "map.finalMessage": "Merci. Nous revenons vers vous rapidement.",
//         "map.finalMessage": "Merci d'avoir localisé votre projet !",
//         "map.finalMessageTxt": "Un conseiller va vous contacter dans les meilleurs délais.",
//     },
//     "it": {
//         "home.welcome": "Localizzazione {name}",
//         "search.placeholder": "Cerca",
//         "geolocation.notsupported": "Geolocalizzazione non funzionante",
//         "geolocation.notsupportedtitle": "Geolocalizzazione",
//         "map.stepOne": "Inserire l’indirizzo completo (oppure cliccare sul bottone a destra per localizzare la posizione corrente).",
//         "map.stepThree": "Definire i confini della proprietà.",
//         "map.stepHangar": "Posizionare il capannone all’interno della proprietà.",
//         "map.stepPropertyRoof": "Selezionare il tetto all’interno della proprietà.",
//         "map.drawRoof": "Disegnare l’area del/i tetto/i interessata",
//         "map.propertyBorders": "Disegnare i confini della proprietà",
//         "map.propertyRoof": "Disegnare l’area del/i tetto/i interessata",
//         "map.propertyHangar": "Posizionare il capannone all’interno della proprietà",
//         "map.propertyRotate": "Ruotare",
//         "map.sendData": "Inviare",
//         "map.resetData": "Ripristina",
//         "map.finalMessage": "Grazie. La ricontatteremo il prima possibile.",
//         "map.finalMessageTxt": " ",
//     },
//     "nl": {
//         "home.welcome": "Locatie {name}",
//         "search.placeholder": "Zoeken",
//         "geolocation.notsupported": "Geolocatie niet ondersteund",
//         "geolocation.notsupportedtitle": "Geolocatie",
//         "map.stepOne": "Gelieve uw adres in te voeren of op de rechter muisknop te klikken om uw positie te bepalen.",
//         "map.stepThree": "Markeer alstublieft de grenzen van uw perceel.",
//         "map.stepHangar": "Please place the hangar to your property.",
//         "map.stepPropertyRoof": "Please define the roof of your property.",
//         "map.drawRoof": "Draw Roof",
//         "map.propertyBorders": "Grenzen markeren",
//         "map.propertyRoof": "Draw roof",
//         "map.propertyHangar": "Place hangar",
//         "map.propertyRotate": "Rotate",
//         "map.sendData": "Verzenden",
//         "map.resetData": "Resetten",
//         "map.finalMessage": "Dank u. We zullen snel terug naar u komen.",
//         "map.finalMessageTxt": " ",
//     },
// };
//
// const locals_ = {
//     en: messages['en'],
//     fr: messages['fr'],
//     it: messages['it'],
//     de: messages['de'],
//     nl: messages['nl'],
// };

let locale = localeProp || defaultLocale;
const AirTable_recordId = window.locationTool.airtableRecordId;
const AirTableID = window.locationTool.airtableTableId;
function AsyncApp(props) {

    const [trans,setLocal] = useState(null);
    const [airtableSettings,setAirtableSettings] = useState(null);

    useEffect(() => {
        getLocals()
    },[locale])


    const getLocals = () => {

        getData('translations',locale,(res) => {

            if(res && typeof res.body === 'object') {
                // if(typeof console === 'object') { console.log('getData',res.body,locale,defaultLocale); }
                if(res.body.length && res.body.length >0) {
                    let lc = {};
                    res.body.forEach((translation) => {
                        lc[translation.key] = translation.translation;
                    })
                    setLocal(lc);
                    getAirtableSettings();
                } else if(locale !== defaultLocale) {
                    // if(typeof console === 'object') { console.log('getData',res.body,locale); }
                    locale = defaultLocale;
                    getLocals();
                }
            }

        });
    }

    const getAirtableSettings = () => {
        getData('airtable-settings'+`/?airtableTableId=${AirTableID}`,null,(res) => {
            if(res && typeof res.body === 'object' && res.body.length && res.body.length >0) {
                let rSettings = res.body[0];
                setAirtableSettings(rSettings);
            }

        });
    }


    if(!trans) {
        return <LoadingIndicator type="container" />;
    }

    if(!airtableSettings) {
        return <LoadingIndicator type="container" />;
    }

    return (
        <IntlProvider
            locale={locale}
            key={localeProp}
            messages={trans}
        >
            <App
                AirTable_recordId={AirTable_recordId}
                airtableSettings={airtableSettings}
            />
        </IntlProvider>
    );

}

export default AsyncApp;


// ReactDOM.render(
//   <React.StrictMode>
//       <IntlProvider
//           locale={locale}
//           key={localeProp}
//           messages={locals[locale]}
//       >
//           <App />
//       </IntlProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
