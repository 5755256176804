import superagent from 'superagent';
import config from '../config';


export function getData(search,locale,callback,o={}) {

    let query = {};
    if(locale) {
        query = {
            _locale: locale
        };
    }

    superagent.get(config.strapiApi.host+'/'+search)
        .query(query) // query string
        .end((err, res) => {
            if(err) {
                if(typeof console === 'object') { console.log('strapiApi.getData Error GET ',search,err,res); }
            } else {
                if(typeof callback === 'function') {
                    callback(res);
                }
            }
        });

}