import React from 'react';
import ReactDOM from 'react-dom';
import AsyncApp from './AsyncApp';

ReactDOM.render(
    <AsyncApp />,
    document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//       <IntlProvider
//           locale={locale}
//           key={localeProp}
//           messages={locals[locale]}
//       >
//           <App />
//       </IntlProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
