import React, {useState, useCallback, useEffect} from 'react';
import './App.scss';
import {FormattedMessage} from 'react-intl';
import MyMapComponent from './scenes/location/map';
import config from './config';
import superagent from 'superagent';
import LoadingIndicator from "./common/LoadingIndicator";
import { usePosition } from './scenes/location/use-position';
import {makeStyles} from "@material-ui/core/styles";
import MapsUtils from "./scenes/location/MapsUtils";
import AirtableUtils from "./scenes/location/AirtableUtils";
// import Airtable from "airtable";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // width: 'calc(100% -10px)',
        marginBottom: '10px'
    },
}));

const firstLocation = 'geocode';
// Airtable.configure({
//     endpointUrl: 'https://api.airtable.com',
//     apiKey: config.airtable.api_key
// });
// // const base = Airtable.base(config.airtable.tableId);
// const base = Airtable.base(window.locationTool.airtableTableId);
// const baseTable = window.locationTool.airtableTableBase;
// const view = window.locationTool.airtableTableView;


const retryLoadingRecord = 10;
let loadingretries = 0;
const testMode = false;

function App(props) {

    const useMarker = false;
    const classes = useStyles();
    const { AirTable_recordId, airtableSettings } = props;
    const [reload, reloadApp] = useState(0);
    const [stateMapData, setSavedMapData] = useState({});
    const [record, setRecord] = useState(false);
    const [sendSpin, setSendSpin] = useState(false);
    const [recordSend, setRecordSend] = useState(false);
    const [recordMode, setRecordMode] = useState( (!!window.locationTool.apiId) );
    const [location, setLocation] = useState();
    const [zoom, setZoom] = useState(config.google.zoom);
    const { latitude, longitude, timestamp, /*accuracy,*/ error } = usePosition(false);

    const reloadAppData = () => {
        reloadApp((reload +1));
    };

    // let savedMapData = window.localStorage.getItem('mapData') || {};
    // if(savedMapData && typeof savedMapData === 'string') {
    //     savedMapData = JSON.parse(savedMapData);
    //     // if(typeof console === 'object') { console.log('typeof savedMapData',typeof savedMapData,savedMapData); }
    // }
    //

    const reSetSavedMapData = (savedMapData) => {

        if(savedMapData && typeof savedMapData === 'string') {
            savedMapData = JSON.parse(savedMapData);
        }

        if(savedMapData && typeof savedMapData === 'object') {
            window.localStorage.setItem('mapData', JSON.stringify(savedMapData, 0, 2));
            getSavedMapData(null,true);
        }
        return savedMapData;
    };

    const getSavedMapData = (
        savedMapData = null,
        init = false
    ) => {

        if(!savedMapData) {
            savedMapData = window.localStorage.getItem('mapData') || {};
        }

        if(savedMapData && typeof savedMapData === 'string') {
            savedMapData = JSON.parse(savedMapData);
            if(init) {
                setSavedMapData(savedMapData);
                setLocation(savedMapData.center);
                setZoom(savedMapData.zoom);
            }
        }
        return savedMapData;
    };

    useEffect(
        () => {
            getSavedMapData(null,true);
        },
        [],
    );




    /**
     * list first page of airtable records
     * https://airtable.com/appEW5tCnvpOdm1l2/api/docs#javascript/table:test:list
     */
    // const listAirtableRecords = () => {
    //     base(baseTable).select({
    //         view: 'Grid view'
    //     }).firstPage(function(err, records) {
    //         if (err) { console.error(err); return; }
    //         records.forEach(function(record) {
    //             // console.log('Retrieved', record.get('Name'));
    //             console.log('Retrieved', record);
    //         });
    //     });
    // };
    //
    // const getAirtableRecords = useEffect(
    //     () => {
    //         listAirtableRecords();
    //     },
    //     [],
    // );

    const updateAirTableRecord = (recordId, fields) => {

        recordId = recordId || record.id || AirTable_recordId;
        setSendSpin(true);


        // base(baseTable).update([
        //     {
        //         "id": recordId,
        //         "fields": fields
        //         // "fields": {
        //         //     "Name": "test",
        //         //     "Address": "Schmiedestr. 7, Ratingen"
        //         // }
        //     }
        // ], function(err, records) {
        //     if (err) {
        //         console.error(err);
        //         return;
        //     }
        //     records.forEach(function(record) {
        //         console.log('updated Record', record);
        //     });
        // });

        superagent.post(config.api.path)
            .send(
                {
                    action: 'PUT',
                    recordId: recordId,
                    tableId: airtableSettings.airtableTableId,//window.locationTool.airtableTableId,
                    tableName: airtableSettings.airtableTableBase,//window.locationTool.airtableTableBase,
                    "fields": fields
                }) // query string
            .end((err, res) => {
                setSendSpin(false);
                // Do something
                // if(typeof console === 'object') { console.log('post retrieveAirtableRecord with response',res); }
                if(res && res.body) {
                    // console.log('UPDATEAirtableRecord', res.body);
                    setRecord(res.body);
                    setRecordSend(true);
                    window.localStorage.removeItem('mapData');
                    setTimeout(() => {

                        if(typeof window.sendScrollTop === 'function') {
                            window.sendScrollTop();
                        }

                        let redirect = AirtableUtils.guessRedirect(airtableSettings);
                        if(redirect) {
                            setTimeout(() => {
                                window.location.href = redirect;
                            }, 4000);
                        } else {
                            if(typeof window.sendHeight === 'function') {
                                window.heightSend = false;
                                window.sendHeight();
                            }
                        }

                    }, 1000);
                }
            });
    };

    const retrieveAirtableRecord = (recordId) => {
        // base(baseTable).find(recordId, function(err, record) {
        //     if (err) { console.error(err); return; }
        //     console.log('retrieveAirtableRecord', record);
        // });

        let sessionId = window.locationTool.airtableSessionId;

        superagent.post(config.api.path)
            .send({
                action: 'GET',
                recordId: recordId,
                tableId: airtableSettings.airtableTableId ,//window.locationTool.airtableTableId,
                tableName: airtableSettings.airtableTableBase,//window.locationTool.airtableTableBase,
                sessionId: sessionId,
            }) // query string
            .end((err, res) => {
                // setSendSpin(false);
                // if(typeof console === 'object') { console.log('GET retrieveAirtableRecord with response',res); }

                let noRecord = "Couldn't load your record. You may proceed in Test-Mode, but you won't be able to send data.";

                if(res && res.body && res.body.success === false) {
                    if(loadingretries < retryLoadingRecord &&
                        ((recordId && recordId.length > 0) || (sessionId && sessionId.length > 0))) {
                        loadingretries++;
                        setTimeout(() => {
                            retrieveAirtableRecord(recordId);
                        },2000);
                        return false;
                    } else {
                        setRecord(null);
                        if(!testMode) {
                            window.alert(noRecord);
                            sendMail('CapitalPV - No record found',null,window.locationTool,res.body);
                        }
                        return false;
                    }
                }

                if(res && res.body) {
                    if(res.body.fields && res.body.fields.lt_mapData) {
                        // if(typeof console === 'object') { console.log('res.body.fields.lt_mapData',res.body.fields.lt_mapData); }
                        reSetSavedMapData(res.body.fields.lt_mapData);
                    } else if(res.body.fields) {
                        let address = AirtableUtils.guessAddress(res.body,airtableSettings);
                        if(address) {
                            return googleGetCoordinatesOfAddress(address,res.body);
                        }
                    } else {
                        reSetSavedMapData();
                    }

                    setRecord(res.body);

                } else {
                    if(recordId && recordId.length > 0 && loadingretries < retryLoadingRecord) {
                        loadingretries++;
                        setTimeout(() => {
                            retrieveAirtableRecord(recordId);
                        },1000);
                    } else {
                        setRecord(null);
                        window.alert(noRecord);
                        // if(typeof console === 'object') { console.log('res',res); }
                        if(res) {
                            sendMail('CapitalPV - No record found', null, window.locationTool, res.body);
                        }
                    }


                }
            });
    };

    const sendMail = (subject,recordId, parameters, response) => {
        superagent.post(config.api.host+'/mail.php')
            .send({
                subject: subject,
                recordId: recordId,
                parameters: parameters,
                response: response,
            }) // query string
            .end((err, res) => {
                // setSendSpin(false);
                // if(typeof console === 'object') { console.log('GET retrieveAirtableRecord with response',res); }
            });
    };

    useEffect(
        () => {
            retrieveAirtableRecord(AirTable_recordId);
        },
        [AirTable_recordId],
    );

    /**
     * get coordinates by address
     * @param address
     * @param record
     */
    const googleGetCoordinatesOfAddress = (address,record) => {
        let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key='+config.google.apiKey;

        superagent
            .get(url)
            .end((err, res) => {
                // Do something
                let mpd = null;
                if(res && res.body && typeof res.body === 'object') {

                    if(res.body.results && typeof res.body.results === 'object' && res.body.results[0]) {
                        setLocation(res.body.results[0].geometry.location);
                        let zoom = 15;
                        setZoom(zoom);
                        mpd = {
                            center: res.body.results[0].geometry.location,
                            mapCenter: res.body.results[0].geometry.location,
                            address: address,
                            zoom: zoom
                        };
                    }
                }

                reSetSavedMapData(mpd);
                setRecord(record);
            });
    };

    const googleGeoLocation = () => {
        let url = 'https://www.googleapis.com/geolocation/v1/geolocate';

        superagent
            .post(url)
            .query({ key: config.google.apiKey }) // query string
            // .use(prefix) // Prefixes *only* this request
            // .use(nocache) // Prevents caching of *only* this request
            .end((err, res) => {
                // Do something
                // if(typeof console === 'object') { console.log('get googleGeoLocation with response',res); }

                if(res && res.body && res.body.location) {
                    setLocation(res.body.location);
                    setZoom(15);
                }
            });
    };

    if(record === false && testMode === false) {
        return <LoadingIndicator type="container" />;
    }

    if(!location) {

        if(firstLocation === 'google') {
            googleGeoLocation();
        } else {
            // if(typeof console === 'object') { console.log('error',error, timestamp,typeof error); }

            if (error) {
                googleGeoLocation();
            } else if (
                timestamp
            ) {
                setLocation({lat: latitude, lng: longitude});
                setZoom(config.google.geoLocationZoom);
            } else if(testMode){
                googleGeoLocation();
            }
        }

        return <LoadingIndicator type="container" />;
    }


  return (
    <div className={classes.root}>
        {recordMode &&<h1>
            <FormattedMessage id="home.welcome" values={{name: ''}}/>
        </h1>}

        {location ? <MyMapComponent
            airtableSettings={airtableSettings}
            savedMapData={stateMapData}
            reloadAppData={reloadAppData}
            getSavedMapData={getSavedMapData}
            setLocation={setLocation}
            setZoom={setZoom}
            updateAirTableRecord={updateAirTableRecord}
            useMarker={useMarker}
            record={record}
            recordMode={recordMode}
            sendSpin={sendSpin}
            recordSend={recordSend}
            options={{
                mapTypeId: config.google.mapType,
                center: location,
                zoom: zoom,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
                // gestureHandling: 'none',
                styles:
                    // https://mapstyle.withgoogle.com/
                    [
                        {
                            "featureType": "poi.attraction",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.government",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.medical",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.place_of_worship",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.school",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
            }}
        /> : <LoadingIndicator type="container" />}
    </div>
  );
}

export default App;
