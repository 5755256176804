class MapsUtils {

    static geoLocate(options) {
        // if(typeof console === 'object') { console.log('navigator.geolocation',navigator.geolocation); }
        if (navigator.geolocation) {
            return new Promise(function (resolve, reject) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    resolve({center: geolocation, radius: position.coords.accuracy});
                }, reject, options);
            });
        } else {
            return new Promise((resolve, reject) => {
                reject('navigator.geolocation not defined');
                return false;
            }).catch(function(error) {
                if(typeof console === 'object') { console.log('error',error); }
            });
        }
    };

    static calcPolygonBounds(center, size, offset = 0, edges = 4) {

        if(typeof console === 'object') { console.log('calcPolygonBounds',center, size); }

        let
            n1 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (0+offset)).lat(),
            n2 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (0+offset)).lng(),

            nw1 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, ((315)+offset)).lat(),
            nw2 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, ((315)+offset)).lng(),

            w1 = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (270+offset)).lat(),
            w2 = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (270+offset)).lng(),

            s1 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (180+offset)).lat(),
            s2 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (180+offset)).lng(),

            se1 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (135+offset)).lat(),
            se2 = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (135+offset)).lng(),

            e1 = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (90+offset)).lat(),
            e2 = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (90+offset)).lng();

        // return new window.google.maps.LatLngBounds(new window.google.maps.LatLng(s,w),
        //     new window.google.maps.LatLng(n,e))

        if(edges === 4) {
            return [
                {lat: n1, lng: n2},
                {lat: w1, lng: w2},
                {lat: s1, lng: s2},
                {lat: e1, lng: e2},
                // {lat: n1, lng: n2},
            ]
        } else if(edges === 5) {
            return [
                {lat: n1, lng: n2},
                {lat: nw1, lng: nw2},
                {lat: w1, lng: w2},
                {lat: s1, lng: s2},
                {lat: e1, lng: e2},
                // {lat: n1, lng: n2},
            ]
        } else {
            return [
                {lat: n1, lng: n2},
                {lat: nw1, lng: nw2},
                {lat: w1, lng: w2},
                {lat: s1, lng: s2},
                {lat: se1, lng: se2},
                {lat: e1, lng: e2},
                // {lat: n1, lng: n2},
            ]
        }
    };
    
    static calcRectangleBounds(center, size, offset = 0) {
        let n = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (0+offset)).lat(),
            s = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (180+offset)).lat(),
            e = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (90+offset)).lng(),
            w = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (270+offset)).lng();

        return new window.google.maps.LatLngBounds(new window.google.maps.LatLng(s,w),
            new window.google.maps.LatLng(n,e))
    }

    static calcOverlayImgBounds(center, size, offset = 0) {
        let n = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (0+offset)).lat(),
            s = window.google.maps.geometry.spherical.computeOffset(center, size.height/2, (180+offset)).lat(),
            e = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (90+offset)).lng(),
            w = window.google.maps.geometry.spherical.computeOffset(center, size.width/2, (270+offset)).lng();

        return {
            north: n,
            south: s,
            east: e,
            west: w
        };
    }

    static getBoundsFromPolygon = function (polygon) {
        // var bounds = new window.google.maps.LatLngBounds();
        // polygon.getPath().forEach(function (element, index) { bounds.extend(element); });
        // return bounds;
        return MapsUtils.getBoundsByPolygonPath(polygon.getPath());
    };

    static getBoundsByPolygonPath(paths) {
        var bounds = new window.google.maps.LatLngBounds();
        paths.forEach(function (element, index) { bounds.extend(element); });
        return bounds;
    };

    static convertRectangleBoundsToPathCoords(rectangle) {
        return [
            { lat: rectangle.getBounds().getNorthEast().lat(), lng: rectangle.getBounds().getNorthEast().lng() },
            { lat: rectangle.getBounds().getNorthEast().lat(), lng: rectangle.getBounds().getSouthWest().lng() },
            { lat: rectangle.getBounds().getSouthWest().lat(), lng: rectangle.getBounds().getSouthWest().lng() },
            { lat: rectangle.getBounds().getSouthWest().lat(), lng: rectangle.getBounds().getNorthEast().lng() }
        ];
    };

    /**
     *
     * @param polygon
     * @returns {google.maps.LatLng | window.google.maps.LatLngBounds.center}
     */
    static getPolygonCenter(polygon) {

        if(!polygon) {
            return null;
        }

        let map = polygon.getMap();
        let prj = map.getProjection();
        let x1 = 0, x2 = 0, y1 = 0, y2 = 0;

        if(!prj) {
            return null;
        }

        /*
        x1, the lowest x coordinate
        y1, the lowest y coordinate
        x2, the highest x coordinate
        y2, the highest y coordinate
         */

        let coords = polygon.getPath().getArray().map(function(latLng){
            let point = prj.fromLatLngToPoint(latLng);

            return {
                x: point.x,
                y: point.y,
            };
        });

        Object.keys(coords).forEach((i) => {
            let p = coords[i];

            if((!x1) || p.x < x1) {
                x1 = p.x;
            }
            if((!y1) || p.y < y1) {
                y1 = p.y;
            }

            if((!x2) || p.x > x2) {
                x2 = p.x;
            }
            if((!y2) || p.y > y2) {
                y2 = p.y;
            }
        });

        let newCenter = {};
        newCenter.x = x1 + ((x2 - x1) / 2);
        newCenter.y = y1 + ((y2 - y1) / 2);

        return prj.fromPointToLatLng(newCenter);
    };

    /**
     * only working, if it a rectangle...
     * @param polygon
     * @returns {google.maps.LatLng}
     */
    static getCenterOfPolygon(polygon) {
        let bounds = MapsUtils.getBoundsFromPolygon(polygon);
        // if(typeof console === 'object') { console.log('bounds',bounds,bounds.getCenter()); }
        return bounds.getCenter();
    };

    static createPolygonFromRectangle(rectangle) {
        var map = rectangle.getMap();

        var coords = MapsUtils.convertRectangleBoundsToPathCoords(rectangle);

        // Construct the polygon.
        var rectPoly = new window.google.maps.Polygon({
            path: coords
        });

        var properties = [
            "editable","draggable","strokeColor","strokeOpacity","strokeWeight","fillOpacity","fillColor","zIndex"
        ];
        //inherit rectangle properties
        var options = {};
        properties.forEach(function(property) {
            if (rectangle.hasOwnProperty(property)) {
                options[property] = rectangle[property];
            }
        });
        rectPoly.setOptions(options);

        rectangle.setMap(null);
        rectPoly.setMap(map);
        return rectPoly;
    }

    /**
     * rotate a polygon
     * @param polygon
     * @param angle
     * @param rotateAtPoint
     * @param rotateAtCenter
     */
    static rotatePolygon(polygon,angle,rotateAtPoint,rotateAtCenter) {
        let coords = MapsUtils.calculatePolygonPathRotation(polygon,angle,rotateAtPoint,rotateAtCenter);
        polygon.setPath(coords);
    }

    static calculatePolygonPathRotation(polygon,angle,rotateAtPoint=0, rotateAtCenter = false) {
        let map = polygon.getMap();
        let prj = map.getProjection();
        let origin = prj.fromLatLngToPoint(polygon.getPath().getAt(rotateAtPoint)); //rotate around first point

        if(rotateAtCenter) {
            let center = MapsUtils.getCenterOfPolygon(polygon);
            origin = prj.fromLatLngToPoint(center);
        }

        let coords = polygon.getPath().getArray().map(function(latLng){
            let point = prj.fromLatLngToPoint(latLng);
            let rotatedLatLng =  prj.fromPointToLatLng(MapsUtils.rotatePoint(point,origin,angle));
            return {lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng()};
        });

        return coords;
    };

    static rotatePoint(point, origin, angle) {
        var angleRad = angle * Math.PI / 180.0;
        return {
            x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
            y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
        };
    }

    static getDrawingCoordinates(drawing) {
        let o = [];
        if(!drawing) return o;

        let vertices = drawing.getPath();

        // Iterate over the vertices.
        for (let i =0; i < vertices.getLength(); i++) {
            let xy = vertices.getAt(i);
            // o[i] = '' + 'Coordinate ' + i + ': ' + xy.lat() + ', ' + xy.lng();
            o[i] = {lat:xy.lat(),lng:xy.lng()};
        }

        return o;
    }

    static geocodePosition(geocoder,pos) {
        // if(typeof console === 'object') { console.log('geocodePosition find pos',pos); }
        return new Promise(function(resolve, reject) {
            geocoder.geocode({
                latLng: pos
            }, function(results, status) {
                if (status === 'OK') {
                    // console.log('geocodePosition.results',results);
                    resolve(results);
                } else {
                    reject(new Error('Couldnt\'t find the location ' + pos));
                }
            })
        })
    }

    static staticMap_getPolygon(overlay,options={}) {

        // if(typeof console === 'object') { console.log('overlay',overlay); }

        let staticMap = "path=";
        let fillC = options['fillColor'] || overlay['fillColor'];
        let strokeC =  options['strokeColor'] || overlay['strokeColor'];
        let weight =  options['strokeWeight'] || overlay['strokeWeight'];
        // let fillOpacity =  options['fillOpacity'] || overlay['fillOpacity'];
        // let strokeOpacity =  options['strokeOpacity'] || overlay['strokeOpacity'];

        if (typeof fillC !== "undefined") staticMap += "fillcolor:" + fillC.replace(/#/, "0x");
        if (typeof weight !== "undefined") staticMap += "%7Cweight:" + weight;
        else staticMap += "%7Cweight:0";

        // if (typeof fillOpacity !== "undefined") staticMap += "%7Cfillopacity:" + fillOpacity;
        // if (typeof strokeOpacity !== "undefined") staticMap += "%7Cstrokeopacity:" + strokeOpacity;

        if (typeof strokeC !== "undefined") staticMap += "%7Ccolor:" + strokeC.replace(/#/, "0x");

        let path = encodeURIComponent(window.google.maps.geometry.encoding.encodePath(overlay.getPath()));
        staticMap += "%7Cenc:" + path;

        return staticMap;
    }

    /*
    static createStaticMap() {
        var fillC, strokeC, weight, path;
        var staticMap = "https://maps.googleapis.com/maps/api/staticmap?size=512x512&maptype=roadmap";
        for (var i = 0; i < overlays.length; i++) {
            if (overlays[i].type == google.maps.drawing.OverlayType.POLYGON || overlays[i].type == google.maps.drawing.OverlayType.POLYLINE) {
                path = encodeURIComponent(google.maps.geometry.encoding.encodePath(overlays[i].overlay.getPath()));
                if (overlays[i].type == google.maps.drawing.OverlayType.POLYGON) {
                    fillC = overlays[i].overlay.get("fillColor");
                    strokeC = overlays[i].overlay.get("strokeColor");
                    weight = overlays[i].overlay.get("strokeWeight");
                    staticMap += "&path=";
                    if (typeof fillC != "undefined") staticMap += "fillcolor:" + fillC.replace(/#/, "0x");
                    if (typeof weight != "undefined") staticMap += "%7Cweight:" + weight;
                    else staticMap += "%7Cweight:0";
                    if (typeof strokeC != "undefined") staticMap += "%7Ccolor:" + strokeC.replace(/#/, "0x");
                    staticMap += "%7Cenc:" + path;
                } else if (overlays[i].type == google.maps.drawing.OverlayType.POLYLINE) {
                    fillC = overlays[i].overlay.get("fillColor");
                    strokeC = overlays[i].overlay.get("strokeColor");
                    weight = overlays[i].overlay.get("strokeWeight");

                    staticMap += "&path=";
                    if (typeof weight != "undefined") staticMap += "weight:" + weight;
                    else staticMap += "weight:2";
                    if (typeof strokeC != "undefined") staticMap += "%7Ccolor:" + strokeC.replace(/#/, "0x");
                    staticMap += "%7Cenc:" + path;
                }
            } else if (overlays[i].type == google.maps.drawing.OverlayType.MARKER) {
                staticMap += "&markers=color:blue|" + overlays[i].overlay.getPosition().toUrlValue(6);

            } else if (overlays[i].type == google.maps.drawing.OverlayType.RECTANGLE) {
                path = [];
                var north = overlays[i].overlay.getBounds().getNorthEast().lat();
                var east = overlays[i].overlay.getBounds().getNorthEast().lng();
                var south = overlays[i].overlay.getBounds().getSouthWest().lat();
                var west = overlays[i].overlay.getBounds().getSouthWest().lng();
                path.push(new google.maps.LatLng(north, east));
                path.push(new google.maps.LatLng(south, east));
                path.push(new google.maps.LatLng(south, west));
                path.push(new google.maps.LatLng(north, west));
                path.push(new google.maps.LatLng(north, east));
                path = encodeURIComponent(google.maps.geometry.encoding.encodePath(path));
                fillC = overlays[i].overlay.get("fillColor");
                strokeC = overlays[i].overlay.get("strokeColor");
                weight = overlays[i].overlay.get("strokeWeight");
                staticMap += "&path=";
                if (typeof fillC != "undefined") staticMap += "fillcolor:" + fillC.replace(/#/, "0x");
                else staticMap += "fillcolor:blue";
                if (typeof weight != "undefined") staticMap += "%7Cweight:" + weight;
                if (typeof strokeC != "undefined") staticMap += "%7Ccolor:" + strokeC.replace(/#/, "0x");
                staticMap += "%7Cenc:" + path;
            } else if (overlays[i].type == google.maps.drawing.OverlayType.CIRCLE) {
                path = drawCircle(overlays[i].overlay.getCenter(),
                    overlays[i].overlay.getRadius(), 1);
                path = encodeURIComponent(google.maps.geometry.encoding.encodePath(path));
                fillC = overlays[i].overlay.get("fillColor");
                strokeC = overlays[i].overlay.get("strokeColor");
                weight = overlays[i].overlay.get("strokeWeight");
                staticMap += "&path=";
                if (typeof fillC != "undefined") staticMap += "fillcolor:" + fillC.replace(/#/, "0x");
                else staticMap += "fillcolor:blue";
                if (typeof weight != "undefined") staticMap += "%7Cweight:" + weight;
                if (typeof strokeC != "undefined") staticMap += "%7Ccolor:" + strokeC.replace(/#/, "0x");
                staticMap += "%7Cenc:" + path;
            }
        }
        document.getElementById('staticMap').innerHTML = staticMap;
        document.getElementById('imageholder').innerHTML = "<img src='" + staticMap + "' alt='static map' / > ";
        document.getElementById('urllen').innerHTML = "URL length =" + staticMap.length + " characters";
    }*/


    static codeAddress() {
        // var address = document.getElementById('address').value;
        // geocoder.geocode({
        //     'address': address
        // }, function(results, status) {
        //     if (status == google.maps.GeocoderStatus.OK) {
        //         map.setCenter(results[0].geometry.location);
        //         if (marker) {
        //             marker.setMap(null);
        //             if (infowindow) infowindow.close();
        //         }
        //         marker = new google.maps.Marker({
        //             map: map,
        //             draggable: true,
        //             position: results[0].geometry.location
        //         });
        //         google.maps.event.addListener(marker, 'dragend', function() {
        //             geocodePosition(marker.getPosition());
        //         });
        //         google.maps.event.addListener(marker, 'click', function() {
        //             if (marker.formatted_address) {
        //                 infowindow.setContent(marker.formatted_address + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
        //             } else {
        //                 infowindow.setContent(address + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
        //             }
        //             infowindow.open(map, marker);
        //         });
        //         google.maps.event.trigger(marker, 'click');
        //     } else {
        //         alert('Geocode was not successful for the following reason: ' + status);
        //     }
        // });
    }
}

export default MapsUtils;
