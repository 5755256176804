import React, {useState, useCallback, useEffect} from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import config from '../../config';
// import Marker from "./Marker";
import { Marker } from '@react-google-maps/api';
import {defineMessages, useIntl} from 'react-intl';
// import { StandaloneSearchBox } from '@react-google-maps/api';
import GoogleSearchBox from "./GoogleSearchBox";
import { DrawingManager } from '@react-google-maps/api';
import MapsUtils from "./MapsUtils";
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from "@material-ui/core/styles";
import CircleNumber from "../../icons/CircleNumber";
import Room from '@material-ui/icons/Room';
import RotateRight from '@material-ui/icons/RotateRight';
import RotateLeft from '@material-ui/icons/RotateLeft';
import Home from '@material-ui/icons/Home';
import BorderClear from '@material-ui/icons/BorderClear';
import House from '@material-ui/icons/House';
import Map from '@material-ui/icons/Map';
import LoadingIndicator from "../../common/LoadingIndicator";
import DumpForm from "../../common/DumpForm";
import hangarImg from './_img/hangar.svg';
import Grid from '@material-ui/core/Grid';
import AirtableUtils from './AirtableUtils';

// import { OverlayView } from '@react-google-maps/api';
// import USGSOverlay from './CustomOverlay';
// import OverlayViewClass from './OverlayView';
/**
 * https://react-google-maps-api-docs.netlify.com/
 * https://www.npmjs.com/package/@react-google-maps/api
 */


function USGSOverlay(bounds, size, image, map, rotation) {

    // Initialize all properties.
    this.bounds_ = bounds;
    this.image_ = image;
    this.size_ = size;
    this.map_ = map;
    this.rotation_ = rotation;

    // if(typeof console === 'object') { console.log('USGSOverlay',this.rotation_ ,this.size_); }


    this.onRemove = function() {
        if(typeof console === 'object') { console.log('onRemove',this); }
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    };

    this.onAdd = () => {

        // if(typeof console === 'object') { console.log('onAddonAddonAddonAddonAdd',true); }
        var div = document.createElement('div');
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';
        // div.style.transformOrigin = 'top right';
        div.style.transformOrigin = 'center';
        div.style.zIndex = 110;
        // div.style.zIndex = 1000000000;
        // div.style.width = '100px';
        // div.style.height = '100px';
        // div.style.backgroundColor = '#000000';
        // div.draggable=true;

        // Create the img element and attach it to the div.
        var img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        // img.style.position = 'absolute';
        div.appendChild(img);

        this.div_ = div;
        // if(typeof console === 'object') { console.log('USGSOverlay.onAdd',this); }
        // let that = this;

        // window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(div);
        // window.google.maps.OverlayView.preventMapHitsFrom(div);

        /*window.google.maps.event.addDomListener(div, 'mousedown',
            function(e){
                if(typeof console === 'object') { console.log('mousedown',e,that.bounds_,this); }
                this.style.cursor='move';
                // that.map.set('draggable',false);
                that.set('origin',e);

                that.moveHandler  =
                    window.google.maps.event.addDomListener(that.get('map').getDiv(),
                        'mousemove',
                        function(e){
                            let origin = that.get('origin');
                            if(typeof console === 'object') { console.log('MOVE',e,origin.clientX,e.clientX,(origin.clientX-e.clientX)); }
                            let left   = origin.clientX-e.clientX;
                            let top    = origin.clientY-e.clientY;
                            let ne    = that.getProjection().fromLatLngToDivPixel(that.bounds_.getNorthEast()),
                                sw    = that.getProjection().fromLatLngToDivPixel(that.bounds_.getSouthWest()),
                                pos    = that.getProjection().fromLatLngToContainerPixel(that.get('position'));

                            // if(typeof console === 'object') { console.log('left,top,pos',left,top,that.get('position'),pos1,that.size_); }
                            div.style.left = (div.offsetLeft - left) + 'px';
                            div.style.top = (div.offsetTop - top) + 'px';
                            // let latLng = that.getProjection().fromDivPixelToLatLng(new window.google.maps.Point(sw.x-left, ne.y-top));

                            // let latLng = that.getProjection().fromDivPixelToLatLng(new window.google.maps.Point(ne.y-top,sw.x-left));
                            //
                            // let bounds = MapsUtils.calcOverlayImgBounds(latLng, that.size_);
                            //
                            // let newbounds = new window.google.maps.LatLngBounds(
                            //     new window.google.maps.LatLng(bounds.south, bounds.west),
                            //     new window.google.maps.LatLng(bounds.north, bounds.east)
                            // );

                            // if(typeof console === 'object') { console.log('latLng',latLng,bounds,newbounds); }
                            that.set('origin',e);
                            // that.bounds_ = newbounds;
                            // that.set('position',latLng);
                            // that.draw();
                        });


            }
        );


        window.google.maps.event.addDomListener(div,'mouseup',function(){
            if(typeof console === 'object') { console.log('mouseupmouseupmouseup',that.moveHandler); }
            that.map.set('draggable',true);
            this.style.cursor='default';
            window.google.maps.event.removeListener(that.moveHandler);
        });
        */

        // Add the element to the "overlayLayer" pane.
        // var panes = this.getPanes();
        this.getPanes().overlayLayer.appendChild(div);
        // this.getPanes().overlayMouseTarget.appendChild(div);
        // this.getPanes().floatPane.appendChild(div);
        if(this.getRotation()) {
            this.rotate(0);
        }
    };

    this.onRemove = function() {
        this.div_.parentNode.removeChild(this.div_);
        this.div_ = null;
    };

    this.setBounds = function(bounds) {
        // if(typeof console === 'object') { console.log('setBounds this.bounds_, bounds ',this.bounds_, bounds); }
        this.bounds_ = bounds;
        this.draw();
    };

    this.getBounds = function() {
        return this.bounds_;
    };

    this.getRotation = () => {
        return this.rotation_;
    };

    this.setRotation = (r) => {
        this.rotation_ = r;
    };

    this.rotate = (r) => {
        let nr = this.getRotation() + r;
        this.div_.style.transform = 'rotate('+nr+'deg)';
        this.setRotation(nr);
    };

    this.proj = () => {
        return this.getProjection();
    };

    this.draw = () => {

        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        var overlayProjection = this.getProjection();

        // ORIGINAL CODE
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        // var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        // var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

        // if(typeof console === 'object') { console.log('draw position sw %o ne %o',sw,ne); }

        // get size without rotation
        // convert bounds without rotation
        let nr = MapsUtils.calculatePolygonPathRotation(propertyRectangle,(360 - this.getRotation()),0, true);
        let nb = MapsUtils.getBoundsByPolygonPath(nr);
        // let ob = MapsUtils.getBoundsByPolygonPath(propertyRectangle.getPath());
        var sw = overlayProjection.fromLatLngToDivPixel(nb.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(nb.getNorthEast());
        // var sw1 = overlayProjection.fromLatLngToDivPixel(nb.getSouthWest());
        // var ne1 = overlayProjection.fromLatLngToDivPixel(nb.getNorthEast());
        // if(typeof console === 'object') { console.log('(ne.x - sw.x)', this.getRotation(),(ne.x - sw.x),(ne1.x - sw1.x)); }

        // Resize the image's div to fit the indicated dimensions.
        var div = this.div_;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        // div.style.width = (ne.x - sw.x) + 'px';
        // div.style.height = (sw.y - ne.y) + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';
        // if(typeof console === 'object') { console.log('DRAW width %o height %o',(ne.x - sw.x),(sw.y - ne.y),div); }
    };

    this.hide = function() {
        if (this.div_) {
            // The visibility property must be a string enclosed in quotes.
            this.div_.style.visibility = 'hidden';
        }
    };

    this.show = function() {
        if (this.div_) {
            // The visibility property must be a string enclosed in quotes.
            this.div_.style.visibility = 'show';
            // if(typeof console === 'object') { console.log('this.div',this.div_,this.div_.style.visibility); }
        }
    };

    this.toggle = function() {
        if (this.div_) {
            if (this.div_.style.visibility === 'hidden') {
                this.show();
            } else {
                this.hide();
            }
        }
    };


    this.remove = function() {
        this.setMap(null);
    };

    this.toggleDOM = function() {
        if (this.getMap()) {
            // Note: setMap(null) calls OverlayView.onRemove()
            this.setMap(null);
        } else {
            this.setMap(this.map_);
        }
    };

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    this.div_ = null;
    // Explicitly call setMap on this overlay.
    this.setMap(map);
}



// const options = {
//     zoomControlOptions: {
//         position: google.maps.ControlPosition.RIGHT_CENTER // ,
//         // ...otherOptions
//     }
// }
// let autocompleteComp;
let mapInstance;
let drawingManagerInstance;
let geocoderInstance;
let markerInstance;
let searchBoxRef;
let propertyBorders;
let propertyRoof;
let propertyRectangle;
let hangar;
let hangarSize;
let addHangarToRectangle = true;
// let propertyRoof;
let infoWindow;


const useStyles = makeStyles((theme) => ({
    gridroot: {
        flexGrow: 1,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        // width: '100%',
        width: 'calc(100% -10px)',
        height: '50px',
        marginBottom: '10px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    stepTextContainer: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        // alignItems: 'center'
    },
    stepsContainer: {
        marginBottom: theme.spacing(4),
    },
    stepsText: {
        flexGrow: 1
    },
    paleText: {
        color: 'rgba(0,0,0,0.45)',
    },
    actionAreaFull: {
        marginTop: theme.spacing(2),
    },
    actionAreaButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    actionAreaButtonsFormControls: {
        marginTop: theme.spacing(3),
    },
    formControlAreaButtons: {
        marginTop: theme.spacing(1.5) * -1,
        marginRight: theme.spacing(1),
    },
    buttons: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    selectFullsize: {
        width: '100%'
    },
    sendButtons: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
}));

const messages = defineMessages({
    stepOne: {
        id: 'map.stepOne',
    },
    stepTwo: {
        id: 'map.stepTwo',
    },
    stepThree: {
        id: 'map.stepThree',
    },
    stepHangar: {
        id: 'map.stepHangar',
    },
    stepSetType: {
        id: 'map.stepSetType',
    },
    stepPropertyRoof: {
        id: 'map.stepPropertyRoof',
    },
    foundMarkerAddress: {
        id: 'map.foundMarkerAddress',
    },
    centerMarkerOnMap: {
        id: 'map.centerMarkerOnMap',
    },
    centerMapByMarker: {
        id: 'map.centerMapByMarker',
    },
    propertyBorders: {
        id: 'map.propertyBorders',
    },
    propertyRoof: {
        id: 'map.propertyRoof',
    },
    propertyHangar: {
        id: 'map.propertyHangar',
    },
    propertyBorderEdges: {
        id: 'map.propertyBorderEdges',
    },
    propertyRotate: {
        id: 'map.propertyRotate',
    },
    type: {
        id: 'map.type',
    },
    propertyTypeHanger: {
        id: 'map.propertyTypeHanger',
    },
    propertyTypeRoof: {
        id: 'map.propertyTypeRoof',
    },
    propertyTypeLand: {
        id: 'map.propertyTypeLand',
    },
    sendData: {
        id: 'map.sendData'
    },
    resetData: {
        id: 'map.resetData'
    },
    finalMessage: {
        id: 'map.finalMessage'
    },
    finalMessageTxt: {
        id: 'map.finalMessageTxt'
    }
});


function MyMapComponent(props) {

    // if(typeof console === 'object') { console.log('MyMapComponent',props); }
    const debug = true;
    let stepIndex = 1;

    const {
        options,
        setLocation,
        setZoom,
        savedMapData,
        getSavedMapData,
        updateAirTableRecord,
        reloadAppData,
        useMarker,
        record,
        recordMode,
        sendSpin,
        recordSend,
        airtableSettings
    } = props;

    const polygonEdgesChooser = false;
    const rotateNumberChooser = false;
    const propertyRoofEdges = 4;
    const propertyTypeChooser = false;
    const classes = useStyles();
    // const [dumpFormIndex, setupdateDumpForm] = useState(0);
    const [mapHasProjection, setMapHasProjection] = useState(false);
    const [zoomChanged, setZoomChanged] = useState(0);
    const [markerAddress, setMarkerAddress] = useState();
    const [markerPosition, setMarkerPosition] = useState(( savedMapData.markerPosition || options.center));
    const [propertyType, setPropertyType] = useState(AirtableUtils.guessPropertyType(savedMapData, record, airtableSettings));
    const [propertyBorderEdges, setpropertyBorderEdges] = useState((savedMapData.propertyBorderEdges || 4));
    const [propertyBorderCoordinates, setpropertyBorderCoordinates] = useState(savedMapData.propertyBorderCoordinates);
    const [propertyRoofCoordinates, setpropertyRoofCoordinates] = useState(savedMapData.propertyRoofCoordinates);
    const [propertyRectangleCoordinates, setpropertyRectangleCoordinates] = useState(savedMapData.propertyRectangleCoordinates);
    const [propertyRectangleRotation, setpropertyRectangleRotation] = useState((savedMapData.propertyRectangleRotation || 0));
    // const [propertyRoofEdges, setpropertyRoofEdges] = useState((savedMapData.propertyRoofEdges || 4));
    // const [propertyRoofCoordinates, setpropertyRoofCoordinates] = useState(savedMapData.propertyRoofCoordinates);
    const intl = useIntl();

    //

    // useEffect(
    //     () => {
    //         if(record) {
    //             if(record['fields'] && record['fields']['Type de projet']) {
    //                 setPropertyType(record['fields']['Type de projet'].toLowerCase());
    //                 // if(typeof console === 'object') { console.log('FOUND NEW Type de projet',record['fields']['Type de projet']); }
    //             }
    //         }
    //     },
    //     [record],
    // );

    const drawingOptions = {
        circleOptions: {
            fillColor: '#ffffff',
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: '#ffffff',
            clickable: false,
            editable: true,
            draggable: true,
            zIndex: 1
        },
        propertyRoof: {
            fillColor: '#000000',
            fillOpacity: 0.25,
            strokeWeight: 3,
            strokeColor: '#000000',
            // clickable: false,
            draggable: true,
            editable: true,
            zIndex: 2
        },
        propertyBorders: {
            fillColor: '#ffffff',
            fillOpacity: 0.25,
            strokeWeight: 3,
            strokeColor: '#ffffff',
            // clickable: false,
            editable: true,
            draggable: true,
            // geodesic: true,
            zIndex: 1
        },
        propertyRectangle: {
            fillColor: '#656984',
            fillOpacity: addHangarToRectangle ? 0 : 0.9,
            strokeWeight: 3,
            strokeColor: '#303339',
            strokeOpacity: addHangarToRectangle ? 0 : 1,
            // clickable: false,
            editable: false,
            draggable: true,
            // geodesic: true,
            zIndex: 2
        },
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.google.apiKey,
        libraries: config.google.libraries,
        language: intl.locale,
        // embedded: false,
        // ...otherOptions
    });

    const onLoad = useCallback(
        function onLoad (instance) {
            mapInstance = instance;
            geocoderInstance = new window.google.maps.Geocoder();
            infoWindow = new window.google.maps.InfoWindow();
            USGSOverlay.prototype = new window.google.maps.OverlayView();
            // if(typeof console === 'object') { console.log('USGSOverlay',USGSOverlay); }
            // if(typeof console === 'object') { console.log('onLoad set mapInstance',mapInstance,geocoderInstance); }
            // let geoCoder = new mapComp.Geocoder();
            // do something with map Instance
            let setAddress = true;

            if(savedMapData && savedMapData.address) {
                setSearchBoxValue(savedMapData.address);
                setAddress = false;
            }

            setMapInstanceCenter(null,setAddress);

            hangarSize = new window.google.maps.Size(15,42);
            // if(typeof console === 'object') { console.log('ONLOAD propertyBorderCoordinates',propertyBorderCoordinates); }
            if(propertyBorderCoordinates) {
                drawPropertyBorder(propertyBorderCoordinates,null);
            }

            if(propertyRoofCoordinates) {
                drawPropertyRoof(propertyRoofCoordinates,null);
            }

            if(propertyRectangleCoordinates) {
                drawRectangle(propertyRectangleCoordinates,null,true, propertyRectangleRotation);
            }

            mapInstance.addListener('projection_changed', () => {
                setMapHasProjection(true);
            });

            // if(propertyRoofCoordinates && propertyType === 'roof') {
            //     drawPropertyRoof(propertyRoofCoordinates,null);
            // }
        }
    );

    const setMapInstanceCenter = (center = null,setAddress = true) => {

        center = center || mapInstance.center;

        MapsUtils.geocodePosition(geocoderInstance,center)
            .then((results) => {
                if(results && results[0]){
                    if(setAddress) {
                        setSearchBoxValue(results[0].formatted_address);
                    }
                    if(useMarker) {
                        setMarkerAddress(results[0].formatted_address);
                    }
                }
            });
    };

    const onZoomChanged = () => {
        if(mapInstance) {
            setZoom(mapInstance.zoom);
            setZoomChanged(mapInstance.zoom);
        }
    };

    const onCenterChanged = () => {
        if(mapInstance) {
            setLocation(mapInstance.center);
        }
    };

    const onLoadMarker = marker => {
        // console.log('onLoadMarker.marker: ', marker);
        markerInstance = marker;
        updateMarkerAddress();
    };

    const onMarkerDragEnd = () => {
        updateMarkerAddress(true);
    };

    // const onMarkerPositionChanged = () => {
    //     updateMarkerAddress();
    // };

    const updateMarkerAddress = (setSearchBox=false) => {
        if( markerInstance ) {
            MapsUtils.geocodePosition(geocoderInstance,markerInstance.position)
                .then((results) => {
                    if(results && results[0]){// if(typeof console === 'object') { console.log('onMarkerDragEnd.results[0]',results[0],results[0].formatted_address); }
                        setMarkerAddress(results[0].formatted_address);
                        if(setSearchBox) {
                            setSearchBoxValue(results[0].formatted_address);
                        }
                    }
                });
        }
    };

    const centerMarkerOnMap = () => {
        setMarkerPosition(mapInstance.center);
        setZoom(mapInstance.zoom);
        setMapInstanceCenter();
    };

    const centerMapByMarker = () => {
        let center = getCenter();
        setLocation(center);
    };

    const getCenter = () => {
        let center = mapInstance.center;
        if(useMarker) {
            center = markerInstance.position || mapInstance.center;
        }
        return center;
    };

    const getSearchBoxRef = () => {
        return searchBoxRef;
    };

    const setSearchBoxRef = (r) => {
        searchBoxRef = r;
    };

    const getSearchBoxValue = () => {
        let sbr = getSearchBoxRef();
        if(sbr && typeof sbr.getInputValue === 'function') {
            return sbr.getInputValue();
        }

        return null;
    };

    const setSearchBoxValue = (formatted_address) => {
        // return false;
        let sbr = getSearchBoxRef();
        if(sbr && typeof sbr.setInputValue === 'function') {
            sbr.setInputValue(formatted_address);
        }
    };

    const clearSearchBoxValue = () => {
        let sbr = getSearchBoxRef();
        if(sbr && typeof sbr.clearinput === 'function') {
            sbr.clearinput();
        }
    };

    const onDrawingManagerLoad = (drawingManager) => {
        drawingManagerInstance = drawingManager;
    };

    const removeDrawings = () => {
        if(propertyRoof) {
            propertyRoof.setMap(null);
        }
        if(propertyBorders) {
            propertyBorders.setMap(null);
        }
        if(hangar) {
            hangar.remove();
        }
        if(propertyRectangle) {
            propertyRectangle.setMap(null);
        }
    };

    const removeDrawing = (type) => {
        if(type === 'propertyBorders' && propertyBorders) {
            propertyBorders.setMap(null);
        }
        else if(type === 'propertyRoof' && propertyRoof) {
            propertyRoof.setMap(null);
        }
    };

    const removeRectangle = () => {
        if(hangar) {
            hangar.remove();
        }
        if(propertyRectangle) {
            propertyRectangle.setMap(null);
        }
    };

    const onPolygonComplete = (polygon) => {
        console.log(polygon);
    };

    const placeDrawingOnMap = (type ,edges = 4) => {
        removeDrawing(type);

        let center = getCenter();

        let size = new window.google.maps.Size(50,50);

        let minZoom = 19;
        let maxZoom = 20;
        if(type === 'propertyRoof') {
            size = new window.google.maps.Size(20,20);
            maxZoom = 21;

        }
        // else if(type === 'propertyHangar') {
        //     size = new window.google.maps.Size(100,100);
        //     maxZoom = 19;
        // }

        let bounds = MapsUtils.calcPolygonBounds(center, size, 40, edges);
        if(typeof console === 'object') { console.log('bounds',type,size,maxZoom,bounds); }
        // if(typeof console === 'object') { console.log('bounds',bounds); }
        if(type === 'propertyBorders') {
            drawPropertyBorder(bounds, center, minZoom, maxZoom);
        }
        else if(type === 'propertyRoof') {
            drawPropertyRoof(bounds,center);
        }
    };

    const getMinMaxZoom = (zoom,minZoom,maxZoom) => {

        // if(minZoom && (propertyType === 'land' || propertyType === 'hangar')) {
        //     minZoom = 16;
        // }

        if(zoomChanged) {
            return zoom;
        }

        if(minZoom && zoom < minZoom) {
            zoom = minZoom;
        }
        if(maxZoom && zoom > maxZoom) {
            zoom = maxZoom;
        }
        return zoom;
    };

    const drawPropertyBorder = (path,center,minZoom,maxZoom) => {

        let options = drawingOptions.propertyBorders;
        options.path = path;
        options.map = mapInstance;
        let zoom = getMinMaxZoom((getSavedMapData().zoom || config.google.drawingZoom),minZoom,maxZoom);
        setZoom(zoom);

        if (center) {
            setLocation(center);
        }

        propertyBorders = new window.google.maps.Polygon(options);
        // if(typeof console === 'object') { console.log('drawPropertyBorder.propertyBorders',propertyBorders); }
        if (debug) {
            propertyBorders.addListener('click', showPolygonArrays);
        }
        propertyBorders.addListener('drag', getpropertyBorderCoordinates);
        propertyBorders.addListener('mouseup', getpropertyBorderCoordinates);
        getpropertyBorderCoordinates();
        // if(typeof console === 'object') { console.log('drawing',drawing,drawing.getPaths()); }
    };

    const drawPropertyRoof = (path,center,minZoom,maxZoom) => {

        let options = drawingOptions.propertyRoof;
        options.path = path;
        options.map = mapInstance;
        let zoom = getMinMaxZoom((getSavedMapData().zoom || config.google.drawingZoom),minZoom,maxZoom);
        setZoom(zoom);

        if (center) {
            setLocation(center);
        }

        propertyRoof = new window.google.maps.Polygon(options);
        if (debug) {
            propertyRoof.addListener('click', showPolygonArrays);
        }
        propertyRoof.addListener('drag', getpropertyRoofCoordinates);
        propertyRoof.addListener('mouseup', getpropertyRoofCoordinates);
        getpropertyRoofCoordinates();
        // if(typeof console === 'object') { console.log('drawing',drawing,drawing.getPaths()); }
    };

    const rotateRectange = (v) => {
        if(propertyRectangle) {
            // let center = propertyRectangle.getBounds();
            // let bounds = MapsUtils.calcRectangleBounds(center, hangarSize,20);
            // propertyRectangle.setBounds(bounds);
            // if(hangar) {
            //     hangar.setBounds(bounds);
            // }
            MapsUtils.rotatePolygon(propertyRectangle,v,0,true);
            if(hangar && addHangarToRectangle) {
                hangar.rotate(v);
            }

            setpropertyRectangleRotation(propertyRectangleRotation + v );
            getpropertyRectangleCoordinates();
        }
    };

    const placeRectangle = () => {
        removeRectangle();
        setpropertyRectangleRotation(0);
        let center = getCenter();
        let bounds = MapsUtils.calcRectangleBounds(center, hangarSize);
        drawRectangle(bounds,center,false, 0, 19, 21);
    };

    const drawRectangle = (bounds,center,createPolygon=false, rotation=0,minZoom,maxZoom) => {

        let options = drawingOptions.propertyRectangle;
        options.map = mapInstance;
        let zoom = getMinMaxZoom((getSavedMapData().zoom || config.google.drawingZoom),minZoom,maxZoom);
        setZoom(zoom);

        if (center) {
            setLocation(center);
        }

        if(createPolygon) {
            options.path = bounds;
            propertyRectangle = new window.google.maps.Polygon(options);
        } else {
            options.bounds = bounds;
            var rectPoly = new window.google.maps.Rectangle(options);
            propertyRectangle = MapsUtils.createPolygonFromRectangle(rectPoly); //create a polygom from a rectangle
        }
        // propertyRectangle.addListener('click', function(e) {
        //     MapsUtils.rotatePolygon(propertyRectangle,10);
        // });

        propertyRectangle.addListener('dragstart', () => {
            // if(typeof console === 'object') { console.log('dragstart',propertyRectangle); }
            propertyRectangle.setOptions({
                fillOpacity: 0.25,
                strokeOpacity: 0.25
            });
            if(hangar && addHangarToRectangle) {
                // hangar.hide();
                // hangar.remove();
            }
        });

        propertyRectangle.addListener('dragend', () => {
            // if(typeof console === 'object') { console.log('dragend',propertyRectangle); }
            propertyRectangle.setOptions({
                fillOpacity: addHangarToRectangle ? 0 : 0.9,
                strokeOpacity: addHangarToRectangle ? 0 : 1
            });
            if(hangar && addHangarToRectangle) {
                // if(typeof console === 'object') { console.log('getBoundsFromPolygon',hangar.getBounds(),MapsUtils.getBoundsFromPolygon(propertyRectangle)); }
                // drawHangar(propertyRectangle.getBounds(),propertyRectangle.getCenter(),size);
                hangar.setBounds(MapsUtils.getBoundsFromPolygon(propertyRectangle));
                // hangar.setBounds(propertyRectangle.getBounds());
                // hangar.show();
            }

            getpropertyRectangleCoordinates();
        });

        // document.getElementById('btnRotate').onclick = function() {
        //     window.setInterval(function() {
        //         MapsUtils.rotatePolygon(rectPoly, 10);
        //     }, 500);
        // };

        if (debug) {
            propertyRectangle.addListener('click', (e) => {showPolygonArrays(e,'propertyRectangle')});
        }
        // propertyRectangle.addListener('drag', getpropertyRectangleCoordinates);
        propertyRectangle.addListener('mouseup', getpropertyRectangleCoordinates);
        getpropertyRectangleCoordinates();

        if(addHangarToRectangle) {
            placeHanger(rotation);
        }
        // if(typeof console === 'object') { console.log('propertyRectangle',propertyRectangle); }
    };

    const placeHanger = (rotation) => {
        let center = getCenter();
        // let size = new window.google.maps.Size(15,42);
        let bounds = MapsUtils.calcOverlayImgBounds(center, hangarSize);
        drawHangar(bounds,center,hangarSize,rotation);
        setpropertyRectangleRotation(rotation);
    };

    const drawHangar = (bounds,center,size,rotation) => {
        //  42x15 meters

        // let bounds = MapsUtils.calcRectangleBounds(center, size, 40, edges);
        // if(typeof console === 'object') { console.log('hangarImg',window.location,hangarImg,bounds); }
        // let img = 'https://www.lib.utexas.edu/maps/historical/newark_nj_1922.jpg';

        // bounds = new window.google.maps.LatLngBounds(
        //     new window.google.maps.LatLng(51.2934769425161, 6.859495594496572),
        //     new window.google.maps.LatLng(51.29350024565112,  6.859432853235146));

        bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(bounds.south, bounds.west),
            new window.google.maps.LatLng(bounds.north, bounds.east)
        );

        hangar = new USGSOverlay(bounds, size, hangarImg, mapInstance,rotation);
        // if(typeof console === 'object') { console.log('hangar',hangar); }

        // hangar = new window.google.maps.GroundOverlay(
        //     hangarImg,
        //     bounds);
        //
        // hangar.setMap(mapInstance);
    };

    // const drawPropertyRoof = (path,center) => {
    //
    //     let options = drawingOptions.propertyRoof;
    //     options.path = path;
    //     options.map = mapInstance;
    //     setZoom((getSavedMapData().zoom || config.google.drawingZoom));
    //
    //     if (center) {
    //         setLocation(center);
    //     }
    //
    //     propertyRoof = new window.google.maps.Polygon(options);
    //     if (debug) {
    //         propertyRoof.addListener('click', showPolygonArrays);
    //     }
    //     propertyRoof.addListener('drag', getpropertyRoofCoordinates);
    //     propertyRoof.addListener('mouseup', getpropertyRoofCoordinates);
    //     getpropertyRoofCoordinates();
    //     // if(typeof console === 'object') { console.log('drawing',drawing,drawing.getPaths()); }
    // };

    // if(typeof console === 'object') { console.log('zoom?',options.zoom); }

    const showPolygonArrays = (event,type) => {
        // Since this polygon has only one path, we can call getPath() to return the
        // MVCArray of LatLngs.
        var vertices;
        if(type === 'propertyRectangle') {
            vertices = propertyRectangle.getPath();
        } else {
            vertices = propertyBorders.getPath();
        }

        var contentString = '<b>Polygon</b><br>' +
            'Clicked location: <br>' + event.latLng.lat() + ', ' + event.latLng.lng() +
            '<br>';

        if (type === 'propertyRectangle') {
            contentString += '' +
                'Rotation:' + propertyRectangleRotation +
                '<br>';
        }

        // Iterate over the vertices.
        for (var i =0; i < vertices.getLength(); i++) {
            var xy = vertices.getAt(i);
            contentString += '<br>' + 'Coordinate ' + i + ':<br>' + xy.lat() + ', ' +
                xy.lng();
        }

        // Replace the info window's content and position.
        infoWindow.setContent(contentString);
        infoWindow.setPosition(event.latLng);
        infoWindow.open(mapInstance);
    };

    const getpropertyRectangleCoordinates = () => {
        // Since this polygon has only one path, we can call getPath() to return the
        // MVCArray of LatLngs.
        let o = MapsUtils.getDrawingCoordinates(propertyRectangle);
        setpropertyRectangleCoordinates(o);
    };

    const getpropertyBorderCoordinates = () => {
        // Since this polygon has only one path, we can call getPath() to return the
        // MVCArray of LatLngs.
        let o = MapsUtils.getDrawingCoordinates(propertyBorders);
        setpropertyBorderCoordinates(o);
    };

    const getpropertyRoofCoordinates = () => {
        // Since this polygon has only one path, we can call getPath() to return the
        // MVCArray of LatLngs.
        let o = MapsUtils.getDrawingCoordinates(propertyRoof);
        setpropertyRoofCoordinates(o);
    };


    const getRotationMenuItems = (minus=false) => {
        let m = [];
        // m.push(<MenuItem value={0}>0</MenuItem>);

        for(let i=0; i<=90;i++) {
            let v = minus ? (i*-1) : i;
            m.push(<MenuItem key={"rotate"+i} value={v}>{v}°</MenuItem>);
        }

        return m;
    };

    const resetMapaData = () => {
        removeDrawings();
        // setSearchBoxValue('');
        setpropertyBorderEdges(4);
        setpropertyBorderCoordinates();
        setpropertyRectangleCoordinates();
        setpropertyRectangleRotation(0);
        clearSearchBoxValue();
    };

    const renderMap = () => {
        // wrapping to a function is useful in case you want to access `window.google`
        // to eg. setup options or create latLng object, it won't be available otherwise
        // feel free to render directly if you don't need that

        if(recordSend === true) {
            return (
                <div>
                    <h1>
                        {intl.formatMessage(messages.finalMessage)}
                    </h1>
                    <p>
                        {intl.formatMessage(messages.finalMessageTxt)}
                    </p>
                </div>
            );
        }

        let xs = 12;
        let sm = 6;
        let md = 6;

        return (
            <div>
                <div className={classes.gridroot}>
                    <Grid container spacing={3}>
                        <Grid item xs={xs} sm={sm} md={md}>
                            <div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepOne)}
                                        <div className={classes.actionAreaFull}>
                                            <GoogleSearchBox
                                                setLocation={setLocation}
                                                setZoom={setZoom}
                                                // getMinMaxZoom={getMinMaxZoom}
                                                setRef={setSearchBoxRef}
                                                setMarkerAddress={setMarkerAddress}
                                                setMarkerPosition={setMarkerPosition}
                                                setMapInstanceCenter={setMapInstanceCenter}
                                                removeDrawings={removeDrawings}
                                            />
                                        </div>
                                    </div>
                                </Typography>

                            </div>
                        </Grid>
                        {useMarker &&<Grid item xs={xs} sm={sm} md={md}>
                            <div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepTwo)}
                                        <span className={classes.paleText}>{markerAddress && <div>{intl.formatMessage(messages.foundMarkerAddress)}: {markerAddress}</div>}</span>
                                        <div className={classes.actionAreaButtons}>
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<Room />}
                                                variant="contained"
                                                color="primary"
                                                onClick={centerMarkerOnMap}
                                            >
                                                {intl.formatMessage(messages.centerMarkerOnMap)}
                                            </Button>

                                            <Button
                                                className={classes.buttons}
                                                startIcon={<Map />}
                                                variant="contained"
                                                color="primary"
                                                onClick={centerMapByMarker}
                                            >
                                                {intl.formatMessage(messages.centerMapByMarker)}
                                            </Button>
                                        </div>
                                    </div>
                                </Typography>
                            </div>
                        </Grid>}
                        {propertyTypeChooser &&<Grid item xs={xs} sm={sm} md={md}>
                            <div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepSetType)}
                                        <div className={classes.actionAreaButtonsFormControls}>
                                            <FormControl className={classes.selectFullsize}>
                                                <InputLabel id="types-select-label"> {intl.formatMessage(messages.type)}</InputLabel>
                                                <Select
                                                    labelId="types-select-label"
                                                    id="types-simple-select"
                                                    value={propertyType}
                                                    onChange={(event) => {
                                                        let v = event.target.value;
                                                        removeDrawings()
                                                        setPropertyType(v);
                                                    }}
                                                >
                                                    <MenuItem value="roof">{intl.formatMessage(messages.propertyTypeRoof)}</MenuItem>
                                                    <MenuItem value="land">{intl.formatMessage(messages.propertyTypeLand)}</MenuItem>
                                                    <MenuItem value="hangar">{intl.formatMessage(messages.propertyTypeHanger)}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Typography>
                            </div>
                        </Grid>}
                        <Grid item xs={xs} sm={sm} md={md}>
                            {<div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepThree)}
                                        <div className={classes.actionAreaButtonsFormControls}>
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<BorderClear />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    placeDrawingOnMap('propertyBorders',propertyBorderEdges);
                                                }}
                                            >
                                                {intl.formatMessage(messages.propertyBorders)}
                                            </Button>
                                            {polygonEdgesChooser && <FormControl className={classes.formControlAreaButtons}>
                                                <InputLabel id="propertyBorderEdges-select-label"> {intl.formatMessage(messages.propertyBorderEdges)}</InputLabel>
                                                    <Select
                                                        labelId="propertyBorderEdges-select-label"
                                                        id="propertyBorderEdges-simple-select"
                                                        value={propertyBorderEdges}
                                                        onChange={(event) => {
                                                            let v = event.target.value;
                                                            setpropertyBorderEdges(v);
                                                            placeDrawingOnMap('propertyBorders',v)
                                                        }}
                                                    >
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={6}>6</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                    </div>
                                </Typography>
                            </div>}
                        </Grid>

                        {propertyType === 'roof' &&<Grid item xs={xs} sm={sm} md={md}>
                            {<div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepPropertyRoof)}
                                        <div className={classes.actionAreaButtonsFormControls}>
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<House />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    placeDrawingOnMap('propertyRoof',propertyRoofEdges);
                                                }}
                                            >
                                                {intl.formatMessage(messages.propertyRoof)}
                                            </Button>
                                        </div>
                                    </div>
                                </Typography>
                            </div>}
                        </Grid>}

                        {propertyType === 'hangar' &&<Grid item xs={xs} sm={sm} md={md}>
                            <div className={classes.stepsContainer}>
                                <Typography variant="body2" component="div" className={classes.stepTextContainer}>
                                    <CircleNumber style={{color: 'rgba(0, 0, 0, 0.38)'}} text={stepIndex++} />
                                    <div className={classes.stepsText}>
                                        {intl.formatMessage(messages.stepHangar)}
                                        <div className={classes.actionAreaButtonsFormControls}>
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<Home />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    placeRectangle();
                                                }}
                                            >
                                                {intl.formatMessage(messages.propertyHangar)}
                                            </Button>
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<RotateRight />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    rotateRectange(45);
                                                }}
                                            >
                                                {intl.formatMessage(messages.propertyRotate)} 45°
                                            </Button>
                                            {rotateNumberChooser &&<FormControl className={classes.formControlAreaButtons}>
                                                <InputLabel id="propertyRotate-select-label"> {intl.formatMessage(messages.propertyRotate)}</InputLabel>
                                                <Select
                                                    labelId="propertyRotate-select-label"
                                                    id="propertyRotate-simple-select"
                                                    value={0}
                                                    onChange={(event) => {
                                                        let v = event.target.value;
                                                        // setpropertyBorderEdges(v);
                                                        rotateRectange(v);
                                                    }}
                                                >
                                                    {getRotationMenuItems()}
                                                </Select>
                                            </FormControl>}
                                            <Button
                                                className={classes.buttons}
                                                startIcon={<RotateLeft />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    rotateRectange(-45);
                                                }}
                                            >
                                                {intl.formatMessage(messages.propertyRotate)} -45°
                                            </Button>
                                            {rotateNumberChooser && <FormControl className={classes.formControlAreaButtons}>
                                                <InputLabel id="propertyRotate-select-label"> {intl.formatMessage(messages.propertyRotate)}</InputLabel>
                                                <Select
                                                    labelId="propertyRotate-select-label"
                                                    id="propertyRotate-simple-select"
                                                    value={0}
                                                    onChange={(event) => {
                                                        let v = event.target.value;
                                                        // setpropertyBorderEdges(v);
                                                        rotateRectange(v);
                                                    }}
                                                >
                                                    {getRotationMenuItems(true)}
                                                </Select>
                                            </FormControl>}
                                        </div>
                                    </div>
                                </Typography>
                            </div>
                        </Grid>}
                        <Grid item xs={xs} sm={sm} sm={md}></Grid>
                    </Grid>
                </div>

                <div id="mapContainer">
                    <GoogleMap
                        mapContainerStyle={{
                            height: "600px",
                            width: "100%"
                        }}
                        // zoom={7}
                        zoom={options.zoom || config.google.zoom}
                        center={options.center || config.google.center}
                        options={options}
                        onLoad={onLoad}
                        onZoomChanged={onZoomChanged}
                        onCenterChanged={onCenterChanged}

                    >
                        {useMarker && options.center && <Marker
                            onLoad={onLoadMarker}
                            onDragEnd={onMarkerDragEnd}
                            // onPositionChanged={onMarkerPositionChanged}
                            animation="DROP"
                            draggable={true}
                            position={markerPosition}
                            zIndex={10000}
                            options={{
                                optimized: false,
                                zIndex: 1000000
                            }}
                        />}

                        <DrawingManager
                            onLoad={onDrawingManagerLoad}
                            onPolygonComplete={onPolygonComplete}
                            options={{
                                // drawingMode: google.maps.drawing.OverlayType.MARKER,
                                drawingControl: true,
                                drawingControlOptions: {
                                    position: window.google.maps.ControlPosition.TOP_LEFT,
                                    drawingModes: []
                                    // drawingModes: ['marker','circle', 'polygon', 'rectangle','polyline']
                                },
                                // markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'},
                                circleOptions: {
                                    fillColor: '#ffffff',
                                    fillOpacity: 0.5,
                                    strokeWeight: 3,
                                    strokeColor: '#ffffff',
                                    clickable: false,
                                    editable: true,
                                    zIndex: 1
                                },
                                polygonOptions: {
                                    fillColor: '#ffffff',
                                    fillOpacity: 0.5,
                                    strokeWeight: 3,
                                    strokeColor: '#ffffff',
                                    clickable: false,
                                    editable: true,
                                    zIndex: 1
                                }
                            }}
                        />

                    </GoogleMap>

                    <DumpForm
                        mapHasProjection={mapHasProjection}
                        zoom={options.zoom || config.google.zoom}
                        center={options.center || config.google.center}
                        markerAddress={markerAddress}
                        markerInstance={markerInstance}
                        markerPosition={markerPosition}
                        propertyBorderCoordinates={propertyBorderCoordinates}
                        propertyRectangleCoordinates={propertyRectangleCoordinates}
                        propertyRoofCoordinates={propertyRoofCoordinates}
                        propertyRectangleRotation={propertyRectangleRotation}
                        // propertyRoofCoordinates={propertyRoofCoordinates}
                        propertyType={propertyType}
                        propertyBorderEdges={propertyBorderEdges}

                        propertyRectangle={propertyRectangle}
                        propertyBorders={propertyBorders}
                        propertyRoof={propertyRoof}
                        // propertyRoofEdges={propertyRoofEdges}
                        updateAirTableRecord={updateAirTableRecord}
                        resetMapaData={resetMapaData}
                        reloadAppData={reloadAppData}
                        useMarker={useMarker}
                        USGSOverlay={USGSOverlay}
                        mapInstance={mapInstance}

                        record={record}
                        recordMode={recordMode}
                        sendSpin={sendSpin}
                        address={getSearchBoxValue()}
                    />
                </div>
            </div>
        )
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <LoadingIndicator type="container" />
}
export default MyMapComponent;