import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexShrink: 0,
        paddingRight: '8px',
        '& > svg': {
            // marginRight: theme.spacing(2),
        },
        '& .MuiStepIcon-text': {
            fill: '#fff',
            fontSize: '0.75rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
        }
    },
}));

function CircleNumberIcon(props) {

    const { text, ...rest } = props;

    return (
        <SvgIcon {...rest}>
            <circle cx="12" cy="12" r="12"></circle>
            <text className="MuiStepIcon-text" x="12" y="16" textAnchor="middle">{text}</text>
        </SvgIcon>
    );
}

export default function CircleNumber(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircleNumberIcon {...props} />
        </div>
    );
}